import { Box, Container, Paper, Stack, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { DataTable, RouterButton } from '../../../components'
import { useWebsitesPage } from '../../../hooks'

export function WebsitesIndexPage () {
  const params = useParams<{ organizationSlug: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const getWebsitesPage = useWebsitesPage({
    organization: params.organizationSlug,
    pageSize: 1_000
  })

  return (
    <Container maxWidth="md">
      <Toolbar disableGutters>
        <Typography variant="h1" sx={{ flexGrow: 1 }}>
          Websites
        </Typography>
        <RouterButton
          to={`/${params.organizationSlug}/create-website`}
          variant="contained"
        >
          {t('WebsitesIndexPage:Create website')}
        </RouterButton>
      </Toolbar>
      <Paper>
        <DataTable
          loading={getWebsitesPage.isLoading}
          getRowKey={row => row.id}
          onRowClick={row => navigate(`/${params.organizationSlug}/websites/${row.slug}`)}
          rows={getWebsitesPage.data?.data}
          cols={[
            {
              header: t('Name'),
              render: row => (
                <Stack alignItems="center" direction="row" gap={1}>
                  {row.favicon?.url && <img src={row.favicon.url} width={24} height={24} />}
                  {!row.favicon?.url && <Box width={24} height={24} />}
                  {row.name}
                </Stack>
              )
            }
          ]}
        />
      </Paper>
    </Container>
  )
}
