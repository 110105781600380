import { GetPostVersionResult } from '@thehive/cms-management-api'
import { useState } from 'react'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { AssetPreview } from '../../../../components'
import { TextField } from '@mui/material'

export type EditPostVersionMediaProps = {
  postVersion: GetPostVersionResult
  onSuccess?: () => void
}

export function EditPostVersionMedia (props: EditPostVersionMediaProps) {
  const { postVersion } = props
  const { t } = useTranslation()
  const client = useClient()
  const [file, setFile] = useState<File | null>(null)
  const [caption, setCaption] = useState<string>(postVersion.media?.caption ?? '')

  const updateMedia = useMutation({
    mutationKey: ['updatePostVersionMedia'],
    mutationFn: async () => {
      if (caption && postVersion.media) await client.assets.updateAssetCaption(postVersion.media.id, { caption })
      if (file) {
        await client.postVersions.updateMedia(postVersion.id, { file })
        props.onSuccess?.()
      }
    }
  })

  const deleteMedia = useMutation({
    mutationKey: ['deletePostVersionMedia'],
    mutationFn: async () => {
      if (postVersion.media) {
        await client.postVersions.deleteMedia(postVersion.id, postVersion.media.id)
        props.onSuccess?.()
      }
    }
  })

  return (
    <>
      <Box display="flex">
        <Typography gutterBottom sx={{ flexGrow: 1 }}>
          {t('EditPostPage:Media')}
        </Typography>
        <Box>
          <Button
            size="small"
            variant="outlined"
            disabled={updateMedia.isPending}
            onClick={() => updateMedia.mutate()}
          >
            {t('Save')}
          </Button>
        </Box>
      </Box>

      {postVersion.media && (
        <Box display="flex" alignItems="center" mb={2}>
          <AssetPreview asset={postVersion.media} />
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => deleteMedia.mutate()}
            disabled={deleteMedia.isPending}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      <input
        type="file"
        onChange={event => {
          const file = event.target.files?.[0]
          if (file) {
            setFile(file)
          }
        }}
      />

      <TextField
        fullWidth
        margin="normal"
        multiline
        rows={2}
        label={t('EditPostPage:Caption')}
        name="title"
        value={caption}
        onChange={e => setCaption(e.target.value)}
      />
    </>
  )
}
