import { useQuery } from '@tanstack/react-query'
import { GetSpacesPageQuery } from '@thehive/cms-management-api'
import { useClient } from './use-client'

export function useSpacesPage (params: GetSpacesPageQuery) {
  const client = useClient()

  const getSpacesPage = useQuery({
    queryKey: ['getSpacesPage', params],
    queryFn: ({ signal }) => client.spaces.getPage(params, signal)
  })

  return getSpacesPage
}
