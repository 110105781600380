import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import { ChangeEvent, memo } from 'react'
import { DataTableCol } from './DataTable'

export type DataTableRowProps<T> = {
  row: T
  cols: DataTableCol<T>[]
  index: number
  selected?: boolean
  hover?: boolean
  onClick?: (row: T) => void
  size?: 'medium' | 'small'
  sx: TableRowProps['sx']
  onSelectedChange?: (event: ChangeEvent<HTMLInputElement>, selected: boolean, row: T, index: number) => void
}

export function DataTableRow<T> (props: DataTableRowProps<T>) {
  const { row, cols, index, selected, onSelectedChange } = props

  return (
    <TableRow
      hover={props.hover}
      onClick={() => props.onClick?.(props.row)}
      sx={props.sx}
    >
      {onSelectedChange && (
        <TableCell padding="checkbox">
          <Checkbox
            size={props.size}
            checked={selected}
            onClick={event => event.stopPropagation()}
            onChange={(event, checked) => onSelectedChange(event, checked, row, index)}
          />
        </TableCell>
      )}
      {cols.map((col, colIndex) => (
        <TableCell
          key={colIndex}
          align={col.align}
          padding={col.padding}
          sx={{ maxWidth: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', minWidth: colIndex === 0 ? '300px' : '0px' }}
        >
          {col.render(row)}
        </TableCell>
      ))}
    </TableRow>
  )
}

export const MemoDataTableRow = memo(DataTableRow) as typeof DataTableRow
