import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { UserRole } from '@thehive/cms-management-api'
import * as yup from 'yup'

const fields = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'email', label: 'Email', type: 'text' }
] as const

export function CreateUserForm () {
  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 'Default' as UserRole
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field')),
      email: yup.string().email(t('Invalid email')).required(t('Required field'))
    }),
    onSubmit: async (values) => {
      try {
        await client.users.create(values)
        enqueueSnackbar(t('CreateUserPage:User created'), { variant: 'success' })
        navigate('/users')
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateUserPage:User already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('CreateUserPage:Error creating user'), { variant: 'error' })
        }
      }
    }
  })
  const handleCancel = useCallback(
    () => {
      if (!formik.dirty || window.confirm(t('Cancel operation? Any changes will be lost'))) {
        navigate('/users')
      }
    },
    [formik.dirty]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      {fields.map(field => (
        <TextField
          key={field.name}
          margin="dense"
          disabled={formik.isSubmitting}
          fullWidth
          name={field.name}
          label={t(field.label)}
          value={formik.values[field.name]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
          helperText={formik.touched[field.name] && formik.errors[field.name] || ' '}
        />
      ))}
      <FormControl>
        <FormLabel>{t('Role')}</FormLabel>
        <RadioGroup
          name="role"
          value={formik.values.role}
          onChange={(_, value) => formik.setFieldValue('role', value)}
        >
          <FormControlLabel value="Default" control={<Radio />} label="Default" />
          <FormControlLabel value="Admin" control={<Radio />} label="Admin" />
        </RadioGroup>
      </FormControl>
      <Button
        sx={{ mt: 2 }}
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('CreateUserPage:Submit')}
      </Button>
      <Button
        sx={{ mt: 1 }}
        fullWidth
        size="large"
        onClick={handleCancel}
      >
        {t('Cancel')}
      </Button>
    </form>
  )
}
