import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useTag (
  organizationSlug: string,
  spaceSlug: string,
  environmentSlug: string,
  tagSlug: string
) {
  const client = useClient()

  const getTag = useQuery({
    queryKey: ['getTagBySlug', organizationSlug, spaceSlug, environmentSlug, tagSlug],
    queryFn: ({ signal }) => client.tags.getBySlug(
      organizationSlug, spaceSlug, environmentSlug, tagSlug, signal
    )
  })

  return getTag
}
