import { useFormik } from 'formik'
import { useClient } from '../../../../hooks'
import { CreateApiKeyResult } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

export type CreateApiKeyFormProps = {
  onSuccess?: (result: CreateApiKeyResult) => void
}

export function CreateApiKeyForm (props: CreateApiKeyFormProps) {
  const client = useClient()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      description: ''
    },
    onSubmit: async (values, helpers) => {
      try {
        const result = await client.apiKeys.create(values)
        helpers.resetForm()
        props.onSuccess?.(result)
        enqueueSnackbar(t('ApiKeysIndexPage:API key created'), { variant: 'success' })
      } catch {

      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TextField
          fullWidth
          size="small"
          disabled={formik.isSubmitting}
          name="description"
          label={t('Description')}
          value={formik.values.description}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
        />
        <Button
          sx={{ ml: 1 }}
          disabled={formik.isSubmitting}
          type="submit"
          variant="contained"
          size="large"
        >
          {t('ApiKeysIndexPage:Submit')}
        </Button>
      </Box>
    </form>
  )
}
