import { useQuery } from '@tanstack/react-query'
import { AuthenticationData } from '@thehive/cms-management-api'
import { PropsWithChildren, useState } from 'react'
import { AuthenticationContext } from '../../contexts'
import { useClient } from '../../hooks'

export type AuthenticationProviderProps = PropsWithChildren

export function AuthenticationProvider (props: AuthenticationProviderProps) {
  const [authenticationData, setAuthenticationData] = useState<AuthenticationData | null>(null)

  const client = useClient()

  const getAuthenticationData = useQuery({
    queryKey: ['authenticationData'],
    queryFn: async ({ signal }) => {
      const result = await client.authentication.getAuthenticatedUser(signal)
      setAuthenticationData(result)
      return result
    }
  })

  return (
    <AuthenticationContext.Provider
      value={{
        isAdmin: authenticationData === null ? null : authenticationData.role === 'Admin',
        authenticationData,
        setAuthenticationData,
        error: getAuthenticationData.error,
        loading: getAuthenticationData.isLoading
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  )
}
