import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import * as yup from 'yup'

export function CreateOrganizationForm () {
  const { t } = useTranslation(['common', 'CreateOrganizationPage'])
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field'))
    }),
    onSubmit: async values => {
      try {
        await client.organizations.create(values)
        enqueueSnackbar(t('CreateOrganizationPage:Organization created'), { variant: 'success' })
        navigate('/')
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateOrganizationPage:Organization already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })
  const handleCancel = useCallback(
    () => {
      if (!formik.dirty || window.confirm(t('Cancel operation? Any changes will be lost'))) {
        navigate('/')
      }
    },
    [formik.dirty]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name || ' '}
      />
      <Button
        sx={{ mt: 1 }}
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('CreateOrganizationPage:Submit')}
      </Button>
      <Button
        sx={{ mt: 1 }}
        fullWidth
        size="large"
        onClick={handleCancel}
      >
        {t('Cancel')}
      </Button>
    </form>
  )
}
