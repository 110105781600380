import {
  Button,
  Container,
  IconButton,
  Paper,
  Skeleton,
  Toolbar,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Breadcrumb, DataTable, RouterButton } from '../../../components'
import { useTag } from '../../../hooks/use-tag'
import { useNavigate, useParams } from 'react-router-dom'
import { useClient, useEnvironment } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { AddTagLocaleDialog } from './components/AddTagLocaleDialog'
import { GetTagLocaleResult } from '@thehive/cms-management-api'
import { DeleteTagLocalesDialog } from './components/DeleteTagLocalesDialog'
import { EditTagLocaleDialog } from './components/EditTagLocaleDialog'
import { ErrorAlert } from '../../../components/ErrorAlert/ErrorAlert'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  tagSlug: string
}

export function TagPage () {
  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()
  const { organizationSlug, spaceSlug, environmentSlug, tagSlug } =
    useParams<ParamsType>()
  const [tagLocaleToEdit, setTagLocaleToEdit] = useState<GetTagLocaleResult>()

  const [selectedLocales, setSelectedLocales] = useState<
  Record<string, GetTagLocaleResult>
  >({})
  const getTag = useTag(
    organizationSlug!,
    spaceSlug!,
    environmentSlug!,
    tagSlug!
  )
  const getEnvironment = useEnvironment(
    organizationSlug!,
    spaceSlug!,
    environmentSlug!
  )
  const [addTagLocaleDialogOpen, setTagLocaleDialogOpen] = useState(false)
  const [deleteTagLocalesDialogOpen, setDeleteTagLocalesDialogOpen] =
    useState(false)
  const [editTagLocalesDialogOpen, setEditTagLocaleDialogOpen] = useState(false)

  const isNotFound = client.isNotFound(getTag.error)
  const selectedLocaleCount = Object.entries(selectedLocales).length

  return (
    <Container maxWidth="md">
      {getTag.isError && !isNotFound && (
        <ErrorAlert
          onClick={() => getTag.refetch()}
          errorMessage={t('TagPage:Error on searching tag')}
          actionMessage={t('Try again')}
        />
      )}

      {isNotFound && (
        <ErrorAlert
          onClick={() => navigate(-1)}
          errorMessage={t('TagPage:Tag not found')}
          actionMessage={t('Return')}
        />
      )}

      {!getTag.isError && (
        <>
          <Breadcrumb
            loading={getTag.isLoading}
            data={[
              { label: t('Home'), to: '/' },
              {
                label: getEnvironment.data?.space.organization.name,
                to: `/${organizationSlug}`
              },
              {
                label: getEnvironment.data?.space.name,
                to: `/${organizationSlug}/${spaceSlug}`
              },
              {
                label: getEnvironment.data?.name,
                to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}`
              },
              {
                label: t('Tags'),
                to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}/tags`
              },
              { label: getTag.data?.name }
            ]}
          />

          <Toolbar disableGutters sx={{ gap: 1 }}>
            {selectedLocaleCount === 0 && (
              <>
                <Typography variant="h1" sx={{ flexGrow: 1 }}>
                  {getTag.isLoading && <Skeleton width="280px" height={48} />}
                  {getTag.data?.name || ''}
                </Typography>
                <RouterButton
                  startIcon={<EditIcon />}
                  variant="contained"
                  to={`/${organizationSlug}/${spaceSlug}/${environmentSlug}/tags/${tagSlug}/edit`}
                  disabled={getTag.isLoading}
                >
                  {t('TagPage:Edit tag')}
                </RouterButton>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={() => setTagLocaleDialogOpen(true)}
                  disabled={getTag.isLoading}
                >
                  {t('TagPage:Create locale')}
                </Button>
              </>
            )}
            {selectedLocaleCount > 0 && (
              <>
                <Typography sx={{ flexGrow: 1 }}>
                  {t('TagPage:Locale selected', {
                    count: selectedLocaleCount
                  })}
                </Typography>
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  disabled={getEnvironment.isLoading}
                  onClick={() => setDeleteTagLocalesDialogOpen(true)}
                >
                  {t('Delete')}
                </Button>
              </>
            )}
          </Toolbar>

          {getTag.data && (
            <>
              <AddTagLocaleDialog
                open={addTagLocaleDialogOpen}
                onClose={() => setTagLocaleDialogOpen(false)}
                onSuccess={() => getTag.refetch()}
                tagId={getTag.data?.id}
              />
              <DeleteTagLocalesDialog
                open={deleteTagLocalesDialogOpen}
                onClose={() => setDeleteTagLocalesDialogOpen(false)}
                onSuccess={() => {
                  setSelectedLocales({})
                  getTag.refetch()
                }}
                tagId={getTag.data.id}
                tagLocales={Object.values(selectedLocales)}
              />
              {tagLocaleToEdit && (
                <EditTagLocaleDialog
                  open={editTagLocalesDialogOpen}
                  onClose={() => {
                    setEditTagLocaleDialogOpen(false)
                    setTagLocaleToEdit(undefined)
                  }}
                  onSuccess={() => getTag.refetch()}
                  tagId={getTag.data.id}
                  tagLocale={tagLocaleToEdit}
                />
              )}
            </>
          )}

          <Paper>
            <DataTable
              loading={getTag.isLoading}
              getRowKey={row => row.slug}
              rows={getTag.data?.tagLocales}
              cols={[
                { header: t('Name'), render: row => row.name },
                {
                  header: t('EnvironmentTagsPage:Locale'),
                  render: row => row.locale,
                  width: 100
                },
                {
                  header: t('Edit'),
                  padding: 'none',
                  render: row => (
                    <IconButton
                      sx={{ marginLeft: 2 }}
                      onClick={() => {
                        setSelectedLocales({})
                        setEditTagLocaleDialogOpen(true)
                        setTagLocaleToEdit(() => ({
                          name: row.name,
                          locale: row.locale,
                          slug: row.slug
                        }))
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ),
                  width: 80
                }
              ]}
              selection={selectedLocales}
              onSelectionChange={setSelectedLocales}
            />
          </Paper>
        </>
      )}
    </Container>
  )
}
