import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { GetWebsiteResult } from '@thehive/cms-management-api'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'

export type CreateWebsitePageDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  website: GetWebsiteResult
}

export function CreateWebsitePageDialog (props: CreateWebsitePageDialogProps) {
  const { open, onClose, onSuccess, website } = props
  const { t } = useTranslation()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      websiteId: website.id,
      name: ''
    },
    onSubmit: async (values, helpers) => {
      try {
        await client.websitePages.create(values)
        enqueueSnackbar(t('WebsitePage:Page created'), { variant: 'success' })
        helpers.resetForm()
        onClose()
        onSuccess()
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('WebsitePage:Page already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {t('WebsitePage:Create page')}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled={formik.isSubmitting}
            fullWidth
            margin="dense"
            label={t('Name')}
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={formik.isSubmitting}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
          >
            {t('Confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
