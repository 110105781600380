import { GetWebsitesPageQuery } from '@thehive/cms-management-api'
import { useClient } from '.'
import { useQuery } from '@tanstack/react-query'

export function useWebsitesPage (params: GetWebsitesPageQuery = {}) {
  const client = useClient()

  const getWebsitesPage = useQuery({
    queryKey: ['getWebsitesPage', params],
    queryFn: ({ signal }) => client.websites.getPage(params, signal)
  })

  return getWebsitesPage
}
