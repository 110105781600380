import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { PostStatus } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'

export type PostStatusesSelectProps = {
  value: PostStatus[]
  onChange: (value: PostStatus[]) => void
  disabled?: boolean
}

const options: PostStatus[] = [
  'Draft',
  'Published',
  'Archived'
]

export function PostStatusesSelect (props: PostStatusesSelectProps) {
  const { t } = useTranslation()

  return (
    <FormControl sx={{ minWidth: 140 }} size="small" disabled={props.disabled}>
      <InputLabel id="demo-multiple-name-label">Status</InputLabel>
      <Select
        size="small"
        label="Status"
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={props.value}
        onChange={(event) => props.onChange(event.target.value as PostStatus[])}
      >
        {options.map(status => (
          <MenuItem
            key={status}
            value={status}
          >
            {t(`PostStatus ${status}`, status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
