import { GetPostTypesPageQuery } from '@thehive/cms-management-api'
import { useClient } from '.'
import { useQuery } from '@tanstack/react-query'

export function usePostTypesPage (params: GetPostTypesPageQuery = {}) {
  const client = useClient()

  const getPostTypesPage = useQuery({
    queryKey: ['getPostTypesPage', params],
    queryFn: ({ signal }) => client.postTypes.getPage(params, signal)
  })

  return getPostTypesPage
}
