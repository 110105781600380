import Box from '@mui/material/Box'
import { useSearchParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { ResetPasswordForm } from './components/ResetPasswordForm'
import { RouterButton } from '../../../components'
import { useTranslation } from 'react-i18next'
import { Container, Paper } from '@mui/material'

export function ResetPasswordPage () {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Paper sx={{ p: 4 }}>
        <Container maxWidth="xs">
          <Box>
            <Typography sx={{ my: 2 }} variant="h1">
              {t('ResetPasswordPage:Reset password')}
            </Typography>

            {token && <ResetPasswordForm token={token} />}

            {!token && (
              <Typography>
                {t('ResetPasswordPage:Invalid lik')}
              </Typography>
            )}

            <RouterButton to="/sign-in" sx={{ mt: 2 }}>
              {t('Back')}
            </RouterButton>
          </Box>
        </Container>
      </Paper>
    </Box>
  )
}
