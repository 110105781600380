import { Box, Container, List, ListItemButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation } from 'react-router-dom'

export function ProfileLayout () {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Container maxWidth="md">
      <Box display="flex">
        <Box width="280px" minWidth="280px">
          <List>
            <ListItemButton
              component={Link}
              to="/profile"
              selected={location.pathname === '/profile'}
            >
              {t('Profile')}
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/profile/change-password"
              selected={location.pathname === '/profile/change-password'}
            >
              {t('Change password')}
            </ListItemButton>
          </List>
        </Box>
        <Box flexGrow={1}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  )
}
