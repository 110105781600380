import { CardActionArea } from '@mui/material'
import Card, { CardProps } from '@mui/material/Card'
import { Link } from 'react-router-dom'

export type RouterCardProps = CardProps & {
  to: string
}

export function RouterCard (props: RouterCardProps) {
  const { to, children, ...cardProps } = props

  return (
    <Card {...cardProps}>
      <CardActionArea component={Link} to={to} sx={{ height: '100%' }}>
        {children}
      </CardActionArea>
    </Card>
  )
}
