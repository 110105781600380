import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useEntryTypeFolder (
  organizationSlug: string,
  spaceSlug: string,
  environmentSlug: string,
  entryTypeFolderSlug: string
) {
  const client = useClient()

  const getEntryType = useQuery({
    queryKey: ['getEntryTypeFolderBySlug', organizationSlug, spaceSlug, environmentSlug, entryTypeFolderSlug],
    queryFn: ({ signal }) => client.entryTypeFolders.getBySlug(organizationSlug, spaceSlug, environmentSlug, entryTypeFolderSlug, signal)
  })

  return getEntryType
}
