import { CmsManagementApi } from '@thehive/cms-management-api'
import { PropsWithChildren, useState } from 'react'
import { ApiClientContext } from '../../contexts'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export type ApiClientProviderProps = PropsWithChildren<{
  client: CmsManagementApi
}>

export function ApiClientProvider (props: ApiClientProviderProps) {
  const [queryClient] = useState(new QueryClient({
    defaultOptions: {
      queries: {
        retry: false
      }
    }
  }))

  return (
    <ApiClientContext.Provider value={props.client}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </ApiClientContext.Provider>
  )
}
