import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useEntry (
  entryId: string
) {
  const client = useClient()

  const getEntry = useQuery({
    queryKey: ['getEntryById', entryId],
    queryFn: ({ signal }) => client.entries.getById(entryId, signal)
  })

  return getEntry
}
