import Breadcrumbs from '@mui/material/Breadcrumbs'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import { RouterLink } from '../RouterLink'

export type BreadcrumbProps = {
  data: {
    label?: ReactNode
    to?: string
  }[]
  loading?: boolean
}

export function Breadcrumb (props: BreadcrumbProps) {
  const { data, loading } = props

  return (
    <Breadcrumbs sx={{ mt: 3 }}>
      {data.map((item, index) => [
        loading && <Skeleton key={`skeleton-${index}`} width={80} />,
        !loading && item.to && (
          <RouterLink key={`link-${index}`} to={item.to} underline="hover">
            {item.label}
          </RouterLink>
        ),
        !loading && !item.to && (
          <Typography key={`text-${index}`}>{item.label}</Typography>
        )
      ])}
    </Breadcrumbs>
  )
}
