import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTagsPage } from '../../../../hooks'

type TagsAutocompleteValue = {
  id: string
  name: string
  slug: string
}

export type TagsAutocompleteProps = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  value: TagsAutocompleteValue[]
  onChange: (value: TagsAutocompleteValue[]) => void
}

export function TagsAutocomplete (props: TagsAutocompleteProps) {
  const { t } = useTranslation()
  const getTags = useTagsPage({
    organization: props.organizationSlug,
    space: props.spaceSlug,
    environment: props.environmentSlug,
    pageSize: 1000
  })

  return (
    <Autocomplete
      multiple
      loading={getTags.isLoading}
      options={(getTags.data?.data || []) as TagsAutocompleteValue[]}
      value={props.value}
      onChange={(_, value) => props.onChange(value)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Tags')}
          margin="normal"
        />
      )}
    />
  )
}
