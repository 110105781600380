import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { Button, TextField } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { GetOrganizationResult } from '@thehive/cms-management-api'

export type CreateSpaceFormProps = {
  organization: GetOrganizationResult
}

export function CreateSpaceForm (props: CreateSpaceFormProps) {
  const { organization } = props
  const { t } = useTranslation(['common', 'CreateSpacePage'])
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      organizationId: props.organization.id,
      name: ''
    },
    onSubmit: async values => {
      try {
        await client.spaces.create(values)
        enqueueSnackbar(t('CreateSpacePage:Space created'), { variant: 'success' })
        navigate(`/${organization.slug}`)
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateSpacePage:Space already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('CreateSpacePage:Error creating space'), { variant: 'error' })
        }
      }
    }
  })
  const handleCancel = useCallback(
    () => {
      if (!formik.dirty || window.confirm(t('Cancel operation? Any changes will be lost'))) {
        navigate(`/${organization.slug}`)
      }
    },
    [formik.dirty]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name || ' '}
      />
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('CreateSpacePage:Submit')}
      </Button>
      <Button
        sx={{ mt: 1 }}
        fullWidth
        size="large"
        onClick={handleCancel}
      >
        {t('Cancel')}
      </Button>
    </form>
  )
}
