import { AppBar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar } from '@mui/material'
import { Link, Outlet } from 'react-router-dom'
import { useAuthentication, useClient } from '../../hooks'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { RouterButton } from '../RouterButton'
import { useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'

export function Layout () {
  const { t } = useTranslation(['common', 'Layout'])
  const client = useClient()
  const authentication = useAuthentication()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const signOut = useMutation({
    mutationKey: ['signOut'],
    mutationFn: async () => {
      await client.authentication.signOut()
      authentication.setAuthenticationData(null)
    }
  })

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Box flexGrow={1}>
            <RouterButton to="/" color="inherit">
              {t('Layout:Home')}
            </RouterButton>
          </Box>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>

          <Menu
            slotProps={{ paper: { sx: { minWidth: '200px' } } }}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/profile" onClick={handleClose}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              {t('Profile')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => signOut.mutate()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              {t('Sign out')}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  )
}
