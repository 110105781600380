import { GetEntryTypesPageQuery, GetEntryTypesPageResult } from '@thehive/cms-management-api'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { ReactNode } from 'react'
import { useEntryTypesPage } from '../../hooks'

export type EntryTypeAutocompleteProps = {
  label?: ReactNode
  value: GetEntryTypesPageResult | null
  onChange: (value: GetEntryTypesPageResult | null) => void
  query?: GetEntryTypesPageQuery
  disabled?: boolean
  size?: 'small' | 'medium'
  name?: string
  margin?: TextFieldProps['margin']
  onBlur?: AutocompleteProps<GetEntryTypesPageResult, false, false, false>['onBlur']
  error?: TextFieldProps['error']
  helperText?: TextFieldProps['helperText']
}

export function EntryTypeAutocomplete (props: EntryTypeAutocompleteProps) {
  const getEntryTypes = useEntryTypesPage({ pageSize: 1_000, ...props.query })

  return (
    <Autocomplete
      disabled={props.disabled}
      sx={{ minWidth: 140 }}
      value={props.value}
      onBlur={props.onBlur}
      onChange={(_, value) => props.onChange(value)}
      loading={getEntryTypes.isLoading}
      options={getEntryTypes.data?.data || []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          name={props.name}
          margin={props.margin}
          label={props.label}
          size={props.size}
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  )
}
