import { useQuery } from '@tanstack/react-query'
import { useClient } from '.'
import { GetUsersPageQuery } from '@thehive/cms-management-api'

export function useUsersPage (params: GetUsersPageQuery = {}) {
  const client = useClient()

  const getUsersPage = useQuery({
    queryKey: ['getUsersPage', params],
    queryFn: ({ signal }) => client.users.getPage(params, signal)
  })

  return getUsersPage
}
