import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  FormHelperText
} from '@mui/material'
import { GetEntryTypesPageResult, ValueType } from '@thehive/cms-management-api'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import * as yup from 'yup'
import { useClient, useEntryType } from '../../../../hooks'
import { EntryTypeAutocomplete } from '../../../../components'

export type CreateFieldFormProps = {
  organizationSlug: string
  spaceSlug: string
  entryTypeSlug: string
}

const checkboxes = [
  { name: 'isLocalizable', label: 'CreateEntryFieldPage:Is localizable' },
  { name: 'isTitle', label: 'CreateEntryFieldPage:Is title' },
  { name: 'isRequiredField', label: 'CreateEntryFieldPage:Is required field' },
  { name: 'isUnique', label: 'CreateEntryFieldPage:Is unique' },
  { name: 'isMultiple', label: 'CreateEntryFieldPage:Is multiple' },
  { name: 'isPublic', label: 'CreateEntryFieldPage:Is public' },
  { name: 'isIndexable', label: 'CreateEntryFieldPage:Is indexable' },
  { name: 'isVisible', label: 'CreateEntryFieldPage:Is visible' }
] as const

export function CreateEntryFieldForm (props: CreateFieldFormProps) {
  const { organizationSlug, spaceSlug, entryTypeSlug } = props
  const { t } = useTranslation()

  const valueTypeOptions = Object.values(ValueType).map((value) => ({
    label: value,
    value
  }))

  const getEntryType = useEntryType(
    organizationSlug,
    spaceSlug,
    entryTypeSlug
  )

  const entryTypeId = getEntryType.data?.id
  const navigate = useNavigate()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      entryTypeId: '',
      name: '',
      description: '',
      valueType: '',
      isLocalizable: false,
      isTitle: false,
      isRequiredField: false,
      isUnique: false,
      isMultiple: false,
      isPublic: false,
      isIndexable: false,
      isVisible: false,
      referencedEntryType: null as GetEntryTypesPageResult | null
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field')),
      valueType: yup.string().required(t('Required field')),
      referencedEntryType: yup.object().nullable()
        .when('valueType', {
          is: (valueType: string) => valueType === ValueType.Reference,
          then: () => yup.object().required(t('Required field'))
        })
    }),
    onSubmit: async ({ referencedEntryType, ...values }) => {
      values.entryTypeId = entryTypeId!
      try {
        await client.entryFields.create({
          referencedEntryTypeId: values.valueType === ValueType.Reference
            ? referencedEntryType?.id
            : undefined,
          ...values
        })
        enqueueSnackbar(t('CreateEntryFieldPage:Entry created'), {
          variant: 'success'
        })
        navigate(`/${organizationSlug}/${spaceSlug}/type/${entryTypeSlug}`)
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateEntryFieldPage:Entry already exists'), {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(t('CreateEntryFieldPage:Error creating entry'), {
            variant: 'error'
          })
        }
      }
    }
  })

  const handleCancel = useCallback(() => {
    if (
      !formik.dirty ||
      window.confirm(t('Cancel operation? Any changes will be lost'))
    ) {
      navigate(`/${organizationSlug}/${spaceSlug}/type/${entryTypeSlug}`)
    }
  }, [formik.dirty])

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ' '}
      />
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        name="description"
        label={t('Description')}
        value={formik.values.description}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={(formik.touched.description && formik.errors.description) || ' '}
      />
      <FormControl
        fullWidth
        margin="dense"
        error={formik.touched.valueType && Boolean(formik.errors.valueType)}
        disabled={formik.isSubmitting}
      >
        <InputLabel>{t('Type')}</InputLabel>
        <Select
          disabled={formik.isSubmitting}
          fullWidth
          name="valueType"
          label={t('Type')}
          value={formik.values.valueType}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.valueType && Boolean(formik.errors.valueType)}
        >
          {valueTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {(formik.touched.valueType && formik.errors.valueType) || ' '}
        </FormHelperText>
      </FormControl>

      {formik.values.valueType === ValueType.Reference && (
        <EntryTypeAutocomplete
          margin="dense"
          label={t('Referenced type')}
          value={formik.values.referencedEntryType}
          name="referencedEntryType"
          onBlur={formik.handleBlur}
          onChange={value => formik.setFieldValue('referencedEntryType', value)}
          query={{
            organization: organizationSlug,
            space: spaceSlug
          }}
          error={formik.touched.referencedEntryType && Boolean(formik.errors.referencedEntryType)}
          helperText={(formik.touched.referencedEntryType && formik.errors.referencedEntryType) || ' '}
        />
      )}

      <FormGroup sx={{ my: 0 }}>
        {checkboxes.map(checkbox => (
          <FormControlLabel
            key={checkbox.name}
            control={
              <Checkbox
                color="primary"
                checked={formik.values[checkbox.name]}
                onChange={formik.handleChange}
                name={checkbox.name}
              />
            }
            label={t(checkbox.label)}
          />
        ))}
      </FormGroup>
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
        style={{ marginTop: 10 }}
      >
        {t('CreateEntryFieldPage:Submit')}
      </Button>
      <Button sx={{ mt: 1 }} fullWidth size="large" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
    </form>
  )
}
