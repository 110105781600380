import { Tooltip, TooltipProps, styled } from '@mui/material'

interface CustomTooltipProps extends TooltipProps {
  className?: string
}

const RequiredFieldTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '0.875rem',
    border: '1px solid #dadde9',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    '&::before': {
      backgroundColor: 'white'
    }
  },
  '& .MuiTooltip-arrow': {
    color: 'white'
  }
})

const ExclamationIcon = () => (
  <div style={{
    width: '24px',
    height: '24px',
    backgroundColor: 'orange',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold'
  }}>
    !
  </div>
)

export const renderTooltipContent = (message: string) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <ExclamationIcon />
    <span style={{ marginLeft: '8px' }}>{message}</span>
  </div>
)

export default RequiredFieldTooltip
