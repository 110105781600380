import { ChangePasswordForm } from './components/ChangePasswordForm'
import { Container, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function ChangePasswordPage () {
  const { t } = useTranslation()

  return (
    <Container>
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('ChangePasswordPage:Change password')}
        </Typography>
      </Toolbar>
      <Paper sx={{ p: 2 }}>
        <ChangePasswordForm />
      </Paper>
    </Container>
  )
}
