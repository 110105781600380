import { useNavigate, useParams } from 'react-router-dom'
import { useWebsite } from '../../../hooks/use-website'
import {
  Button,
  Container,
  Divider,
  Paper,
  Skeleton,
  Toolbar,
  Typography
} from '@mui/material'
import { useWebsitePagesPage } from '../../../hooks'
import { DataTable } from '../../../components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { CreateWebsitePageDialog } from './components/CreateWebsitePageDialog'

type Params = {
  organizationSlug: string
  websiteSlug: string
}

export function WebsitePage () {
  const params = useParams<Params>()
  const { t } = useTranslation()
  const [createPageDialogOpen, setCreatePageDialogOpen] = useState(false)
  const navigate = useNavigate()
  const getWebsite = useWebsite(params.organizationSlug!, params.websiteSlug!)
  const getWebsitePagesPage = useWebsitePagesPage({
    organization: params.organizationSlug,
    website: params.websiteSlug,
    pageSize: 1_000
  })

  return (
    <Container maxWidth="md">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {getWebsite.isLoading && <Skeleton width={120} />}
          {getWebsite.data?.name}
        </Typography>
      </Toolbar>

      {getWebsite.data && (
        <CreateWebsitePageDialog
          open={createPageDialogOpen}
          onClose={() => setCreatePageDialogOpen(false)}
          onSuccess={() => getWebsitePagesPage.refetch()}
          website={getWebsite.data}
        />
      )}
      <Paper>
        <Toolbar>
          <Typography variant="h2" sx={{ flexGrow: 1 }}>
            {t('Pages')}
          </Typography>
          <Button
            disabled={getWebsitePagesPage.isLoading}
            variant="contained"
            onClick={() => setCreatePageDialogOpen(true)}
          >
            {t('WebsitePage:New page')}
          </Button>
        </Toolbar>
        <Divider />
        <DataTable
          loading={getWebsitePagesPage.isLoading}
          getRowKey={row => row.id}
          onRowClick={row => navigate(`/${params.organizationSlug}/websites/${params.websiteSlug}/${row.slug}`)}
          rows={getWebsitePagesPage.data?.data}
          cols={[
            { header: t('Name'), render: row => row.name }
          ]}
        />
      </Paper>
    </Container>
  )
}
