import { GetTagsPageQuery } from '@thehive/cms-management-api'
import { useClient } from '.'
import { useQuery } from '@tanstack/react-query'

export function useTagsPage (params: GetTagsPageQuery = {}) {
  const client = useClient()

  const getTagsPage = useQuery({
    queryKey: ['getTagsPage', params],
    queryFn: ({ signal }) => client.tags.getPage(params, signal)
  })

  return getTagsPage
}
