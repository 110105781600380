import { GetSpaceResult } from '@thehive/cms-management-api'
import { useClient } from '../../../../hooks'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { Button, TextField } from '@mui/material'

export type CreatePostTypeFormProps = {
  space: GetSpaceResult
}

export function CreatePostTypeForm (props: CreatePostTypeFormProps) {
  const { space } = props
  const { t } = useTranslation(['common', 'CreatePostTypePage'])
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      spaceId: space.id,
      name: ''
    },
    onSubmit: async values => {
      try {
        await client.postTypes.create(values)
        enqueueSnackbar(t('CreatePostTypePage:Post type created'), { variant: 'success' })
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreatePostTypePage: Post type already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('CreatePostTypePage: Error creating post type'), { variant: 'error' })
        }
      }
    }
  })

  const handleCancel = useCallback(
    () => {
      if (!formik.dirty || window.confirm(t('Cancel operation? Any changes will be lost'))) {
        navigate(`/${space.organization.slug}/${space.slug}`)
      }
    },
    [formik.dirty]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name || ' '}
      />
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('CreatePostTypePage:Submit')}
      </Button>
      <Button
        sx={{ mt: 1 }}
        fullWidth
        size="large"
        onClick={handleCancel}
      >
        {t('Cancel')}
      </Button>
    </form>
  )
}
