import { List, ListItem, ListItemText } from '@mui/material'
import { GetPostVersionsPageResult } from '@thehive/cms-management-api'
import { FormattedDate } from '../../../../components'

export type PostVersionsListProps = {
  postVersions: GetPostVersionsPageResult[]
}

export function PostVersionsList (props: PostVersionsListProps) {
  const { postVersions } = props

  return (
    <List disablePadding>
      {postVersions.map(postVersion => (
        <ListItem key={postVersion.id}>
          <ListItemText
            primary={`v${postVersion.versionNumber}`}
            secondary={<FormattedDate date={postVersion.createdAt} />}
          />
        </ListItem>
      ))}
    </List>
  )
}
