import { ThemeOptions } from '@mui/material'

const primary = '#121212'
const secondary = '#4397f7'

export const customThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    },
    background: {
      default: '#f9f6f4'
    },
    info: {
      main: '#70b5f5',
      contrastText: '#ffffff'
    },
    success: {
      main: '#82cb68',
      contrastText: '#ffffff'
    },
    error: {
      main: '#d5528b',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#edbb5e',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#2b2b2b'
    }
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    h1: {
      color: primary,
      fontSize: '32px',
      fontWeight: 'bold'
    },
    h2: {
      color: primary,
      fontSize: '24px',
      fontWeight: 'bold'
    },
    h3: {
      color: primary,
      fontSize: '20px',
      fontWeight: 'bold'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap'
        },
        head: {
          backgroundColor: '#ffffff'
        }
      }
    }
  }
}
