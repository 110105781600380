import { useParams } from 'react-router-dom'
import { CreateEntryTypeFolderForm } from './components/CreateEntryTypeFolderForm'
import { Container, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEnvironment, useSpace } from '../../../hooks'
import { Breadcrumb } from '../../../components'

export function CreateEntryTypeFolderPage () {
  const { t } = useTranslation(['common', 'CreateEntryTypePage'])
  const { organizationSlug, spaceSlug, environmentSlug } = useParams<{ organizationSlug: string, spaceSlug: string, environmentSlug: string }>()
  const getSpace = useSpace(organizationSlug!, spaceSlug!)
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)

  return (
    <Container maxWidth="sm">
      <Breadcrumb
        loading={getSpace.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          { label: getEnvironment.data?.space.organization.name, to: `/${organizationSlug}` },
          { label: getEnvironment.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
          { label: getEnvironment.data?.name, to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}` },
          { label: t('CreateEntryTypeFolderPage:Create folder') }
        ]}
      />
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateEntryTypeFolderPage:Create folder')}
        </Typography>
      </Toolbar>
      {getSpace.isLoading && <LinearProgress />}
      {getSpace.data && getEnvironment.data && (
        <Paper sx={{ p: 2 }}>
          <CreateEntryTypeFolderForm
            spaceSlug={spaceSlug || ''}
            environmentSlug={environmentSlug || ''}
            spaceId={getSpace.data.id}
            environmentId={getEnvironment.data.id}
            organizationSlug={organizationSlug || ''}/>
        </Paper>
      )}
    </Container>
  )
}
