import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { GetEntryFieldsPageResult } from '@thehive/cms-management-api'

type EntryFieldsSelectProps = {
  values: GetEntryFieldsPageResult[]
  disabled?: boolean
  onChange: (value: string) => void
  entryFieldNameSelected: string | null
}

export function EntryFieldsSelect (props: EntryFieldsSelectProps) {
  const defaultValue = props.values.find(({ isTitle }) => isTitle)?.id || ''
  const value = props.values.find(({ name }) => name === props.entryFieldNameSelected)?.id || defaultValue

  return (
    <FormControl sx={{ width: 120 }} size="small" disabled={props.disabled}>
      <InputLabel id="demo-multiple-name-label">Campo</InputLabel>
      <Select
        size="small"
        label="Campo"
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        defaultValue={defaultValue}
        value={value}
        onChange={(event) => {
          const entryFieldName = props.values.find(({ id }) => id === event.target.value)?.name
          props.onChange(entryFieldName || '')
        }}
      >
        {props.values.map(entryField => (
          <MenuItem
            key={entryField.id}
            value={entryField.id}
          >
            {entryField.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
