import Box from '@mui/material/Box'
import format from 'date-fns/format'
import isDate from 'date-fns/isDate'
import ptBR from 'date-fns/locale/pt-BR'

export type FormattedDateProps = {
  date: Date | string
  format?: string
}

export function FormattedDate (props: FormattedDateProps) {
  const date = isDate(props.date) ? props.date as Date : new Date(props.date)

  const formatted = format(
    date,
    props.format || 'Pp',
    { locale: ptBR }
  )

  return (
    <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
      {formatted}
    </Box>
  )
}
