import { CmsManagementApi } from '@thehive/cms-management-api'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from 'date-fns/locale/pt-BR'
import { SnackbarProvider } from 'notistack'
import { PropsWithChildren } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PostHogProvider } from 'posthog-js/react'
import { PostHogConfig } from 'posthog-js'
import {
  ApiClientProvider,
  AuthenticationProvider,
  CustomThemeProvider
} from './components'

const postHogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST
}

export type AppProvidersProps = PropsWithChildren<{
  apiClient: CmsManagementApi
}>

export function AppProviders (props: AppProvidersProps) {
  return (
    <ApiClientProvider client={props.apiClient}>
      <PostHogProvider
        apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
        options={postHogOptions}
      >
        <AuthenticationProvider>
          <CustomThemeProvider>
            <SnackbarProvider>
              <DndProvider backend={HTML5Backend}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                  {props.children}
                </LocalizationProvider>
              </DndProvider>
            </SnackbarProvider>
          </CustomThemeProvider>
        </AuthenticationProvider>
      </PostHogProvider>
    </ApiClientProvider>
  )
}
