import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'
import { GetOrganizationsPageQuery } from '@thehive/cms-management-api'

export function useOrganizationsPage (params: GetOrganizationsPageQuery = {}) {
  const client = useClient()

  const getOrganizationsPage = useQuery({
    queryKey: ['organizations.getPage', params],
    queryFn: () => client.organizations.getPage(params)
  })

  return getOrganizationsPage
}
