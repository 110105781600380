import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useEnvironment (
  organizationSlug: string,
  spaceSlug: string,
  environmentSlug: string
) {
  const client = useClient()

  const getEnvironment = useQuery({
    queryKey: ['getEnvironmentBySlugs', organizationSlug, spaceSlug, environmentSlug],
    queryFn: ({ signal }) => client.environments.getBySlugs(organizationSlug, spaceSlug, environmentSlug, signal)
  })

  return getEnvironment
}
