import { GetPostsPageQuery } from '@thehive/cms-management-api'
import { useClient } from '.'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export function usePostsPage (params: GetPostsPageQuery = {}) {
  const client = useClient()

  const getPostsPage = useQuery({
    queryKey: ['getPostsPage', params],
    queryFn: ({ signal }) => client.posts.getPage(params, signal),
    placeholderData: keepPreviousData
  })

  return getPostsPage
}
