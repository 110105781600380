import { Button, Container, Paper, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb, DataTable } from '../../../components'
import { useEnvironment, useTagsPage } from '../../../hooks'
import { CreateTagDialog } from './components/CreateTagDialog'
import { useState } from 'react'
import { GetTagResult } from '@thehive/cms-management-api'
import { DeleteTagsDialog } from './components/DeleteTagsDialog'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
}

export function EnvironmentTagsPage () {
  const { t } = useTranslation()
  const { organizationSlug, spaceSlug, environmentSlug } = useParams<ParamsType>()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedTags, setSelectedTags] = useState<Record<string, GetTagResult>>({})
  const navigate = useNavigate()
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const getTagsPage = useTagsPage({
    organization: organizationSlug,
    space: spaceSlug,
    environment: environmentSlug,
    pageSize: 1_000
  })
  const selectedTagsCount = Object.entries(selectedTags).length

  return (
    <Container maxWidth="md">
      <Breadcrumb
        loading={getEnvironment.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          { label: getEnvironment.data?.space.organization.name, to: `/${organizationSlug}` },
          { label: getEnvironment.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
          { label: getEnvironment.data?.name, to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}` },
          { label: t('Tags') }
        ]}
      />
      <Toolbar disableGutters>
        {selectedTagsCount === 0 && (
          <>
            <Typography variant="h1" sx={{ flexGrow: 1 }}>{t('Tags')}</Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setCreateDialogOpen(true)}
              variant="contained"
              disabled={getEnvironment.isLoading}
            >
              {t('EnvironmentTagsPage:New tag')}
            </Button>
          </>
        )}
        {selectedTagsCount > 0 && (
          <>
            <Typography sx={{ flexGrow: 1 }}>
              {t('EnvironmentTagsPage:Tags selected', { count: selectedTagsCount })}
            </Typography>
            <Button
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialogOpen(true)}
              variant="outlined"
              disabled={getEnvironment.isLoading}
            >
              {t('Delete')}
            </Button>
          </>
        )}
      </Toolbar>

      {getEnvironment.data && (
        <CreateTagDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          environment={getEnvironment.data}
          onSuccess={() => getTagsPage.refetch()}
        />
      )}
      <DeleteTagsDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onSuccess={() => {
          setSelectedTags({})
          getTagsPage.refetch()
        }}
        tags={Object.values(selectedTags)}
      />

      <Paper>
        <DataTable
          loading={getTagsPage.isLoading}
          getRowKey={row => row.id}
          rows={getTagsPage.data?.data}
          onRowClick={row => navigate(`/${organizationSlug}/${spaceSlug}/${environmentSlug}/tags/${row.slug}`)}
          selection={selectedTags}
          onSelectionChange={setSelectedTags}
          cols={[
            { header: t('Name'), render: row => row.name },
            { header: t('EnvironmentTagsPage:Locales'), render: row => row.tagLocales.length, width: 100 },
            { header: t('Posts'), render: row => row.postsCount, width: 100 }
          ]}
        />
      </Paper>
    </Container>
  )
}
