import { Container, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSpace } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { CreateEnvironmentForm } from './components/CreateEnvironmentForm'

export function CreateEnvironmentPage () {
  const { t } = useTranslation()
  const params = useParams<{ organizationSlug: string, spaceSlug: string }>()
  const getSpace = useSpace(params.organizationSlug!, params.spaceSlug!)

  return (
    <Container maxWidth="xs">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateEnvironmentPage:Create environment')}
        </Typography>
      </Toolbar>
      {getSpace.isLoading && <LinearProgress />}

      {getSpace.data && (
        <Paper sx={{ p: 2 }}>
          <CreateEnvironmentForm space={getSpace.data} />
        </Paper>
      )}
    </Container>
  )
}
