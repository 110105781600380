import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

export function NoTitle () {
  const { t } = useTranslation()

  return (
    <Typography variant="body2" fontStyle="italic" color="text.secondary">
      {t('No title')}
    </Typography>
  )
}
