import {
  Button
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import {
  useClient, useEnvironment
} from '../../../../hooks'
import { useEntryType } from '../../../../hooks/use-entry-type'

export type CreateEntryFormProps = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  entryTypeSlug: string
}

export function CreateEntriesForm (props: CreateEntryFormProps) {
  const { organizationSlug, spaceSlug, environmentSlug, entryTypeSlug } = props

  const getEnvironment = useEnvironment(
    organizationSlug,
    spaceSlug,
    environmentSlug
  )

  const getEntryType = useEntryType(
    organizationSlug,
    spaceSlug,
    entryTypeSlug
  )

  const environmentId = getEnvironment.data?.id
  const entryTypeId = getEntryType.data?.id

  const navigate = useNavigate()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      environmentId: '',
      entryTypeId: ''
    },
    onSubmit: async (values) => {
      try {
        values.entryTypeId = entryTypeId!
        values.environmentId = environmentId!
        await client.entries.create(values)
        enqueueSnackbar(t('CreateEntryFieldPage:Entry created'), {
          variant: 'success'
        })
        navigate(
          `/${organizationSlug}/${spaceSlug}/${environmentSlug}/${entryTypeSlug}/`
        )
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateEntryFieldPage:Entry already exists'), {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(t('CreateEntryFieldPage:Error creating entry'), {
            variant: 'error'
          })
        }
      }
    }
  })

  const handleCancel = useCallback(() => {
    if (
      !formik.dirty ||
      window.confirm(t('Cancel operation? Any changes will be lost'))
    ) {
      navigate(`/entries/${entryTypeId}/${environmentId}`)
    }
  }, [formik.dirty])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
        style={{ marginTop: 10 }}
      >
        {t('CreateEntryFieldPage:Submit')}
      </Button>
      <Button sx={{ mt: 1 }} fullWidth size="large" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
    </form>
  )
}
