import { Box, Button, Chip, Container, Paper, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Breadcrumb, DataTable, FormattedDate, NoTitle, PostStatusChip, PostStatusesSelect, PostTypeAutocomplete } from '../../../components'
import { useClient, useEnvironment, usePostsPage } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { GetPostTypeResult, GetPostsPageResult, PostStatus } from '@thehive/cms-management-api'
import { enqueueSnackbar } from 'notistack'
import { useDebounce } from 'use-debounce'
import { DeletePostsButton } from './components/DeletePostsButton'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
}

const defaultPageNumber = 0
const defaultPageSize = 25

export function EnvironmentPostsPage () {
  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [postType, setPostType] = useState<GetPostTypeResult | null>(null)
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce(search, 300)
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { organizationSlug, spaceSlug, environmentSlug } = useParams<ParamsType>()
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const getPostsPage = usePostsPage({
    organization: organizationSlug!,
    space: spaceSlug!,
    environment: environmentSlug!,
    postType: postType?.slug,
    statuses: searchParams.getAll('statuses') as PostStatus[],
    pageNumber: Number(searchParams.get('pageNumber')) || defaultPageNumber,
    pageSize: Number(searchParams.get('pageSize')) || defaultPageSize,
    search: debouncedSearch || undefined
  })
  const handlePageNumberChange = (pageNumber: number) => {
    setSearchParams(searchParams => {
      searchParams.set('pageNumber', pageNumber.toString())
      return searchParams
    })
    setSelection({})
  }
  const handlePageSizeChange = (pageSize: number) => {
    setSearchParams(searchParams => {
      searchParams.set('pageSize', pageSize.toString())
      searchParams.delete('pageNumber')
      return searchParams
    })
    setSelection({})
  }
  const handleStatusesChange = (statuses: PostStatus[]) => {
    setSearchParams(searchParams => {
      searchParams.delete('statuses')
      searchParams.delete('pageNumber')
      for (const status of statuses) {
        searchParams.append('statuses', status)
      }
      return searchParams
    })
    setSelection({})
  }
  const [selection, setSelection] = useState<Record<string, GetPostsPageResult>>({})
  const createPost = useMutation({
    mutationKey: ['createPost'],
    mutationFn: async () => {
      await client.posts.create({
        environmentId: getEnvironment.data!.id,
        postTypeId: postType?.id
      })
      getPostsPage.refetch()
      enqueueSnackbar(t('EnvironmentPostsPage:Post created'), { variant: 'success' })
    }
  })
  const updateStatus = useMutation({
    mutationKey: ['updateStatus'],
    mutationFn: async (data: { status: PostStatus, posts: GetPostsPageResult[] }) => {
      await client.posts.updateStatuses({
        status: data.status,
        postIds: data.posts.map(post => post.id)
      })
      getPostsPage.refetch()
      if (data.status === 'Archived') {
        enqueueSnackbar(
          t('EnvironmentPostsPage:Posts archived', { count: data.posts.length }),
          { variant: 'success' }
        )
      } else {
        enqueueSnackbar(
          t('EnvironmentPostsPage:Posts published', { count: data.posts.length }),
          { variant: 'success' }
        )
      }
    }
  })
  const selectionCount = Object.values(selection).length

  return (
    <Container maxWidth="lg">
      <Breadcrumb
        loading={getEnvironment.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          { label: getEnvironment.data?.space.organization.name, to: `/${organizationSlug}` },
          { label: getEnvironment.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
          { label: getEnvironment.data?.name, to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}` },
          { label: t('Posts') }
        ]}
      />
      <Toolbar disableGutters sx={{ gap: 1 }}>
        {selectionCount === 0 && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            gap: 1,
            marginBottom: 1
          }}>
            <Typography variant="h1" sx={{
              flexGrow: 1,
              fontSize: isMobile ? '1.5rem' : '2rem'
            }}
            >{t('Posts')}</Typography>
            <TextField
              size="small"
              disabled={getPostsPage.isLoading}
              placeholder={t('Search')}
              value={search}
              onChange={event => {
                setSearch(event.target.value)
                setSearchParams(searchParams => {
                  searchParams.delete('pageNumber')
                  return searchParams
                })
              }}
            />
            <PostTypeAutocomplete
              query={{ organization: organizationSlug, space: spaceSlug }}
              disabled={getPostsPage.isLoading}
              value={postType}
              onChange={value => {
                setPostType(value)
                setSearchParams({ pageNumber: '0' })
              }}
            />
            <PostStatusesSelect
              disabled={getPostsPage.isLoading}
              value={searchParams.getAll('statuses') as PostStatus[]}
              onChange={handleStatusesChange}
            />
            <Button
              onClick={() => createPost.mutate()}
              variant="contained"
              disabled={getPostsPage.isLoading || createPost.isPending}
            >
              {t('EnvironmentPostsPage:New post')}
            </Button>
          </Box>
        )}
        {selectionCount > 0 && (
          <>
            <Typography sx={{ flexGrow: 1 }}>
              {t('EnvironmentPostsPage:Posts selected', { count: selectionCount })}
            </Typography>
            <DeletePostsButton
              postIds={Object.values(selection).map(post => post.id)}
              onDelete={() => {
                setSelection({})
                getPostsPage.refetch()
              }}
            />
            <Button
              disabled={updateStatus.isPending}
              variant="outlined"
              size="small"
              onClick={() => updateStatus.mutate({ status: 'Published', posts: Object.values(selection) })}
            >
              {t('EnvironmentPostsPage:Publish')}
            </Button>
            <Button
              disabled={updateStatus.isPending}
              variant="outlined"
              size="small"
              onClick={() => updateStatus.mutate({ status: 'Archived', posts: Object.values(selection) })}
            >
              {t('EnvironmentPostsPage:Archive')}
            </Button>
          </>
        )}
      </Toolbar>

      <Paper>
        <DataTable
          TableContainerProps={{ sx: { maxHeight: 'calc(100vh - 64px - 48px - 64px - 52px - 2px)' } }}
          stickyHeader
          size="small"
          loading={getPostsPage.isLoading}
          getRowKey={row => row.id}
          rows={getPostsPage.data?.data}
          selection={selection}
          onSelectionChange={setSelection}
          onRowClick={row => navigate(`/${organizationSlug}/${spaceSlug}/${environmentSlug}/posts/${row.id}`)}
          cols={[
            {
              header: t('Title'),
              render: row => row.lastVersion?.title || <NoTitle />
            },
            {
              header: t('Type'),
              render: row => row.type?.name || '-',
              width: 120
            },
            {
              header: t('Status'),
              render: row => <PostStatusChip status={row.status} size="small" />,
              width: 120
            },
            {
              header: t('Creation date'),
              render: row => <FormattedDate date={row.createdAt} />,
              width: 160
            },
            {
              header: t('Tags'),
              render: row => {
                const tags = row.lastVersion?.tags || []
                const firstTag = tags[0]
                const hasMoreTags = tags.length > 1

                return (
                  <Tooltip
                    title={hasMoreTags ? tags.join(', ') : ''}
                    placement="top"
                    arrow
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {firstTag
                        ? (
                        <Chip
                          label={firstTag}
                          size="small"
                          sx={{ maxWidth: '100%' }}
                        />
                          )
                        : '-'}
                      {hasMoreTags && <Chip
                        label="+"
                        size="small"
                        sx={{ maxWidth: '100%' }}
                      />}
                    </Box>
                  </Tooltip>
                )
              },
              width: 160
            },
            {
              header: t('EditPostPage:Order'),
              render: row => row.order,
              width: 160
            }
          ]}
          pageNumber={Number(searchParams.get('pageNumber')) || defaultPageNumber}
          pageSize={Number(searchParams.get('pageSize')) || defaultPageSize}
          totalSize={getPostsPage.data?.totalSize}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Paper>
    </Container>
  )
}
