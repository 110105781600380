import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'
import * as yup from 'yup'
import { GetTagLocaleResult } from '@thehive/cms-management-api'

export type EditTagLocaleDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  tagId: string
  tagLocale: GetTagLocaleResult
}

export function EditTagLocaleDialog (props: EditTagLocaleDialogProps) {
  const {
    open,
    onClose,
    onSuccess,
    tagId,
    tagLocale: { name, locale }
  } = props
  const { t } = useTranslation()
  const client = useClient()

  const formik = useFormik({
    initialValues: {
      name
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field'))
    }),
    onSubmit: async ({ name }, helpers) => {
      try {
        await client.tags.updateTagLocale(tagId, locale, { name })
        enqueueSnackbar(t('TagPage:Locale updated'), { variant: 'success' })
        helpers.resetForm()
        onClose()
        onSuccess()
      } catch (error) {
        enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
      }
    }
  })

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{t('TagPage:Update locale')}</DialogTitle>
        <DialogContent>
          <TextField
            disabled={formik.isSubmitting}
            fullWidth
            margin="dense"
            label={t('Name')}
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={(formik.touched.name && formik.errors.name) || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={formik.isSubmitting} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
