import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { Button, TextField } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useCallback } from 'react'
import * as yup from 'yup'

const fields = [
  { name: 'currentPassword', label: 'ChangePasswordPage:Current password', type: 'password' },
  { name: 'newPassword', label: 'ChangePasswordPage:New password', type: 'password' }
] as const

export function ChangePasswordForm () {
  const { t } = useTranslation()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: ''
    },
    validationSchema: yup.object().shape({
      currentPassword: yup.string().required(t('Required field')),
      newPassword: yup.string()
        .required(t('Required field'))
        .min(6, t('Password must have at least {{length}} characters', { length: 6 }))
    }),
    onSubmit: async (values, helpers) => {
      try {
        await client.authentication.changePassword(values)
        enqueueSnackbar(t('ChangePasswordPage:Password changed'), { variant: 'success' })
        helpers.resetForm()
      } catch (error) {
        if (client.isBadRequest(error)) {
          enqueueSnackbar(t('ChangePasswordPage:Invalid current password'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('ChangePasswordPage:Error changing password'), { variant: 'error' })
        }
      }
    }
  })
  const handleCancel = useCallback(
    () => {
      if (!formik.dirty || window.confirm(t('Cancel operation? Any changes will be lost'))) {
        formik.resetForm()
      }
    },
    [formik.dirty]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      {fields.map(field => (
        <TextField
          key={field.name}
          type={field.type}
          margin="dense"
          disabled={formik.isSubmitting}
          fullWidth
          name={field.name}
          label={t(field.label)}
          value={formik.values[field.name]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
          helperText={formik.touched[field.name] && formik.errors[field.name] || ' '}
        />
      ))}
      <Button
        sx={{ mt: 2 }}
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('Confirm')}
      </Button>
      <Button
        sx={{ mt: 1 }}
        fullWidth
        size="large"
        onClick={handleCancel}
      >
        {t('Cancel')}
      </Button>
    </form>
  )
}
