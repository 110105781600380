import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'
import * as yup from 'yup'

export type AddTagLocaleDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  tagId: string
}

export function AddTagLocaleDialog (props: AddTagLocaleDialogProps) {
  const { open, onClose, onSuccess, tagId } = props
  const { t } = useTranslation()
  const client = useClient()

  const formik = useFormik({
    initialValues: {
      name: '',
      locale: 'pt-BR'
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field')),
      locale: yup.string().required(t('Required field'))
    }),
    onSubmit: async ({ name, locale }, helpers) => {
      try {
        await client.tags.createTagLocale(tagId, { name, locale })
        enqueueSnackbar(t('TagPage:Locale created'), { variant: 'success' })
        helpers.resetForm()
        onClose()
        onSuccess()
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('TagPage:Locale already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {t('TagPage:Create locale')}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled={formik.isSubmitting}
            fullWidth
            margin="dense"
            label={t('Name')}
            name="name"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name || ''}
          />
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel id="demo-simple-select-label">{t('Locale')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t('Locale')}
              name="locale"
              value={formik.values.locale}
              onChange={formik.handleChange}
            >
              <MenuItem value="pt-BR">Português (Brasil)</MenuItem>
              <MenuItem value="en">Inglês</MenuItem>
              <MenuItem value="esp">Espanhol</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={formik.isSubmitting}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
          >
            {t('Confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
