import { useQuery } from '@tanstack/react-query'
import { useClient } from '.'

export function usePostVersion (postVersionId?: string) {
  const client = useClient()

  const getPost = useQuery({
    queryKey: ['getPostVersionById', postVersionId],
    queryFn: ({ signal }) => client.postVersions.getById(postVersionId!, signal),
    enabled: Boolean(postVersionId)
  })

  return getPost
}
