import { useQuery } from '@tanstack/react-query'
import { useClient } from './index.js'

export function useLastWebsitePageVersion (
  websitePageId?: string
) {
  const client = useClient()

  const getLastWebsiteVersion = useQuery({
    queryKey: ['getLastWebsiteVersion', websitePageId],
    queryFn: ({ signal }) => client.websitePageVersions.getLastVersion(websitePageId!, signal),
    enabled: Boolean(websitePageId)
  })

  return getLastWebsiteVersion
}
