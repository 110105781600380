import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

export function NotFoundPage () {
  const { t } = useTranslation(['NotFoundPage'])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h1">
        {t('NotFoundPage:Page not found')}
      </Typography>
    </Box>
  )
}
