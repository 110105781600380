import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { GetEntriesPageResult } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import { useClient } from '../../../../hooks'

export type DeleteEntriesDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  entries: GetEntriesPageResult[]
}

export function DeleteEntriesDialog (props: DeleteEntriesDialogProps) {
  const { open, onClose, onSuccess, entries } = props
  const { t } = useTranslation()
  const client = useClient()
  const deleteEntries = useMutation({
    mutationKey: ['deleteEntries'],
    mutationFn: async (entries: GetEntriesPageResult[]) => {
      try {
        await client.entries.delete({
          entryIds: entries.map(tag => tag.id)
        })
        onClose()
        onSuccess()
        enqueueSnackbar(
          t('EntriesIndexPage:Entries deleted', { count: entries.length }),
          { variant: 'success' }
        )
      } catch {
        t('EntriesIndexPage:Error deleting entries')
      }
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t('EntriesIndexPage:Delete entries', { count: entries.length })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('This action is irreversible')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={deleteEntries.isPending}
          onClick={() => onClose()}
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={deleteEntries.isPending}
          onClick={() => deleteEntries.mutate(entries)}
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
