import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'

export type DeletePostsButtonProps = {
  postIds: string[]
  onDelete: () => void
}

export function DeletePostsButton (props: DeletePostsButtonProps) {
  const { t } = useTranslation()
  const client = useClient()
  const [open, setOpen] = useState(false)
  const deletePosts = useMutation({
    mutationKey: ['deletePosts'],
    mutationFn: async (postIds: string[]) => {
      await client.posts.delete({ postIds })
      enqueueSnackbar(
        t('EnvironmentPostsPage:Posts deleted', { count: postIds.length }),
        { variant: 'success' }
      )
      props.onDelete()
    }
  })

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        size="small"
        onClick={() => setOpen(true)}
      >
        {t('Delete')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          {t('EnvironmentPostsPage:Delete posts', { count: props.postIds.length })}
        </DialogTitle>
        <DialogActions>
          <Button
            disabled={deletePosts.isPending}
            onClick={() => setOpen(false)}
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={deletePosts.isPending}
            onClick={() => deletePosts.mutate(props.postIds)}
          >
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
