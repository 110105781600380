import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEntriesPage } from '../../../hooks/use-entry-page'
import { Breadcrumb, DataTable, DataTableCol, EntryStatusChip, NoTitle } from '../../../components'
import { Button, Container, Toolbar, Typography, Link, Skeleton, Paper, TextField, Tooltip } from '@mui/material'
import { useEntryType } from '../../../hooks/use-entry-type'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'
import { useClient, useEntryFieldsPage, useEnvironment } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { GetEntriesPageResult } from '@thehive/cms-management-api'
import { useCallback, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import GetApp from '@mui/icons-material/GetApp'
import { DeleteEntriesDialog } from './components/DeleteEntriesDialog'
import { useDebounce } from 'use-debounce'
import { EntryFieldsSelect } from '../../../components/EntryFieldsSelect'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  entryTypeSlug: string
}

const defaultPageNumber = 0
const defaultPageSize = 10

export function EntriesIndexPage () {
  const { organizationSlug, spaceSlug, environmentSlug, entryTypeSlug } = useParams<ParamsType>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selection, setSelection] = useState<Record<string, GetEntriesPageResult>>({})
  const searchEntryField = searchParams.get('searchEntryField')
  const selectedEntryField = searchParams.get('selectedEntryField')
  const [debouncedSearch] = useDebounce(searchEntryField || '', 300)
  const pageNumber = Number(searchParams.get('pageNumber')) || defaultPageNumber
  const pageSize = Number(searchParams.get('pageSize')) || defaultPageSize
  const getEntryType = useEntryType(organizationSlug!, spaceSlug!, entryTypeSlug!)
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const environmentId = getEnvironment.data?.id
  const entryTypeId = getEntryType.data?.id
  const getEntryFields = useEntryFieldsPage({ entryTypeId, pageSize: 1000 })
  const entryFields = getEntryFields.data
  const selectedEntryFieldId = entryFields?.data.find(({ name }) => name === selectedEntryField)?.id

  const getEntriesPage = useEntriesPage({
    organization: organizationSlug,
    space: spaceSlug,
    environment: environmentSlug,
    entryType: entryTypeSlug,
    pageNumber,
    pageSize,
    entryFieldId: selectedEntryFieldId,
    search: debouncedSearch || undefined
  })

  const navigate = useNavigate()
  const client = useClient()
  const createEntry = useMutation({
    mutationKey: ['createEntry'],
    mutationFn: async () => {
      try {
        const entry = await client.entries.create({
          entryTypeId: entryTypeId!,
          environmentId: environmentId!
        })

        enqueueSnackbar(t('CreateEntryFieldPage:Entry created'), { variant: 'success' })
        navigate(`/${organizationSlug}/${spaceSlug}/${environmentSlug}/${entryTypeSlug}/${entry.id}`)
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateEntryFieldPage:Entry already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('CreateEntryFieldPage:Error creating entry'), { variant: 'error' })
        }
      }
    }
  })

  const handleEntryFieldSearchChange = (field: string) => {
    setSearchParams(searchParams => {
      searchParams.delete('pageNumber')
      searchParams.set('selectedEntryField', field)
      return searchParams
    })
    setSelection({})
  }

  const buildEntryHref = useCallback(
    (row: GetEntriesPageResult) => {
      return `/${organizationSlug}/${spaceSlug}/${environmentSlug}/${entryTypeSlug}/${row.id}`
    },
    [
      organizationSlug,
      spaceSlug,
      environmentSlug,
      entryTypeSlug
    ]
  )

  const handleEntryRowClick = useCallback(
    (row: GetEntriesPageResult) => {
      navigate(buildEntryHref(row))
    },
    [organizationSlug, spaceSlug, environmentSlug, entryTypeSlug]
  )

  const removeHtmlTags = (html: string): string => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  const exportToCSV = () => {
    if (!getEntriesPage.data) return

    const fields = ['Name']

    if (getEntriesPage.data?.data[0]?.entryType) {
      const getFields = getEntriesPage.data?.data[0].entryType?.entryFields

      if (getFields) {
        getFields.forEach((field) => {
          fields.push(field.name)
        })
      }
    }

    fields.push('Status')

    const rows = getEntriesPage.data.data.map(row => {
      const rowData = [removeHtmlTags(row.name || 'No Title')]

      if (row.entryType?.entryFields) {
        row.entryType.entryFields.forEach((field) => {
          const value = field.entryValues?.value || '-'
          rowData.push(removeHtmlTags(value))
        })
      }

      rowData.push(row.status)

      return rowData
    })

    const csvContent = [
      fields.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.href = url
    link.setAttribute('download', `${getEntryType.data?.name}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const entriesFields = () => {
    const fields: DataTableCol<GetEntriesPageResult>[] = [{
      header: t('Name'),
      render: (row) => (
        <Tooltip
          title={row.name ?? ''}
        >
          <Link onClick={() => handleEntryRowClick(row)}>
            {row.name || <NoTitle />}
          </Link>
        </Tooltip>
      )
    }]

    if (getEntriesPage.data?.data[0]?.entryType) {
      const getFields = getEntriesPage.data?.data[0].entryType?.entryFields

      if (getFields) {
        for (const field of getFields) {
          const rowField = {
            header: field.name,
            render: (row: GetEntriesPageResult) => {
              const value = row.entryType.entryFields.find((rowField) => rowField.name === field.name)?.entryValues?.value

              return (
                <Tooltip
                  title={value ?? ''}
                >
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {value || '-'}
                  </Typography>

                </Tooltip>
              )
            }
          }

          fields.push({ ...rowField })
        }
      }
    }

    fields[fields.length] = {
      header: t('Status'),
      render: row => <EntryStatusChip status={row.status} size="small" />,
      width: 120
    }

    return fields
  }

  const selectionCount = Object.keys(selection).length

  return (
    <Container maxWidth="md">
      <Breadcrumb
        loading={getEnvironment.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          {
            label: getEnvironment.data?.space.organization.name,
            to: `/${organizationSlug}`
          },
          {
            label: getEnvironment.data?.space.name,
            to: `/${organizationSlug}/${spaceSlug}`
          },
          {
            label: getEnvironment.data?.name,
            to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}`
          },
          { label: getEntryType.data?.name }
        ]}
      />
      <Toolbar disableGutters sx={{ gap: 1 }}>
        {selectionCount === 0 && (
          <>
            <Typography variant="h2" sx={{ flexGrow: 1 }}>
              {getEntryType.isLoading && <Skeleton width={120} />}
              {getEntryType.data?.name}
            </Typography>
            <TextField
              size="small"
              disabled={getEntryType.isLoading}
              placeholder={t('Search')}
              value={searchEntryField}
              onChange={event => {
                setSearchParams(searchParams => {
                  searchParams.delete('pageNumber')
                  if (event.target.value.length === 0) {
                    searchParams.delete('searchEntryField')
                  } else {
                    searchParams.set('searchEntryField', event.target.value)
                  }
                  return searchParams
                })
              }}
            />
            {entryFields?.data && (
              <EntryFieldsSelect
                disabled={getEntryFields.isLoading}
                onChange={handleEntryFieldSearchChange}
                values={entryFields?.data}
                entryFieldNameSelected={selectedEntryField}
              />
            )}
            <Button
              disabled={getEntryType.isLoading || createEntry.isPending}
              variant="contained"
              onClick={() => createEntry.mutate()}
            >
              {t('EntriesIndexPage:New entry')}
            </Button>
            <Tooltip
              title={t('EntriesIndexPage:Export CSV')}
            >
              <Button
                variant="outlined"
                onClick={exportToCSV}
              >
                <GetApp/>
              </Button>
            </Tooltip>
          </>
        )}
        {selectionCount > 0 && (
          <>
            <Typography sx={{ flexGrow: 1 }}>
              {t('EntriesIndexPage:Entries selected', { count: selectionCount })}
            </Typography>
            <Button
              startIcon={<DeleteIcon />}
              variant="outlined"
              color="error"
              onClick={() => setDeleteDialogOpen(true)}
            >
              {t('Delete')}
            </Button>
          </>
        )}
      </Toolbar>

      <DeleteEntriesDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onSuccess={() => {
          getEntriesPage.refetch()
          setSelection({})
        }}
        entries={Object.values(selection)}
      />

      <Paper>
        <DataTable
          loading={getEntriesPage.isLoading}
          selection={selection}
          onSelectionChange={setSelection}
          getRowKey={(row) => row.id}
          onRowClick={(row) => navigate(buildEntryHref(row))}
          rows={getEntriesPage.data?.data || []}
          cols={entriesFields()}
          pageNumber={Number(searchParams.get('pageNumber')) || defaultPageNumber}
          pageSize={Number(searchParams.get('pageSize')) || defaultPageSize}
          totalSize={getEntriesPage.data?.totalSize}
          onPageNumberChange={pageNumber => setSearchParams(searchParams => {
            if (pageNumber === defaultPageNumber) {
              searchParams.delete('pageNumber')
            } else {
              searchParams.set('pageNumber', pageNumber.toString())
            }
            return searchParams
          })}
          onPageSizeChange={pageSize => setSearchParams({ pageSize: pageSize.toString() })}
        />
      </Paper>
    </Container>
  )
}
