import { Divider, Skeleton, Toolbar, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useLastWebsitePageVersion, useWebsitePage } from '../../../hooks'

type Params = {
  organizationSlug: string
  websiteSlug: string
  websitePageSlug: string
}

export function WebsitePagePage () {
  const params = useParams<Params>()
  const getWebsitePage = useWebsitePage(
    params.organizationSlug!,
    params.websiteSlug!,
    params.websitePageSlug!
  )
  const getWebsitePageVersion = useLastWebsitePageVersion(getWebsitePage.data?.id)

  return (
    <>
      <Toolbar>
        <Typography variant="h1">
          {getWebsitePage.isLoading && <Skeleton width={120} />}
          {getWebsitePage.data?.name}
        </Typography>
      </Toolbar>

      <Divider />

      {getWebsitePageVersion.data && (
        <>
        </>
      )}
    </>
  )
}
