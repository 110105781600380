import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { GetTagResult } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import { useClient } from '../../../../hooks'

export type DeleteTagsDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  tags: GetTagResult[]
}

export function DeleteTagsDialog (props: DeleteTagsDialogProps) {
  const { open, onClose, onSuccess, tags } = props
  const { t } = useTranslation()
  const client = useClient()
  const deleteTags = useMutation({
    mutationKey: ['deleteTags'],
    mutationFn: async (tags: GetTagResult[]) => {
      try {
        await client.tags.delete({
          tagIds: tags.map(tag => tag.id)
        })
        onClose()
        onSuccess()
        enqueueSnackbar(
          t('EnvironmentTagsPage:Tags deleted', { count: tags.length }),
          { variant: 'success' }
        )
      } catch {
        t('EnvironmentTagsPage:Error deleting tags')
      }
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t('EnvironmentTagsPage:Delete tags', { count: tags.length })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('This action is irreversible')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={deleteTags.isPending}
          onClick={() => onClose()}
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={deleteTags.isPending}
          onClick={() => deleteTags.mutate(tags)}
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
