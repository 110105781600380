import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { GetPostResult } from '@thehive/cms-management-api'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { formatScore, ScoreTextField } from '../../../../components/ScoreTextField'

export type EditPostScoreFormProps = {
  post: GetPostResult
}

export function EditPostScoreForm (props: EditPostScoreFormProps) {
  const { post } = props
  const { t } = useTranslation()
  const client = useClient()
  const [score, setScore] = useState(post.score ? post.score.toString().replace('.', '') : '0')
  const updateScore = useMutation({
    mutationKey: ['updatePostScore', post],
    mutationFn: async () => {
      try {
        const parsedScore = score ? parseFloat(formatScore(score)) : 0
        await client.posts.updateScore(post.id, { score: parsedScore })
        enqueueSnackbar(t('EditPostPage:Post score updated'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(t('EditPostPage:Error updating post score'), { variant: 'error' })
      }
    }
  })

  return (
    <form>
      <Box display="flex">
        <Typography gutterBottom sx={{ flexGrow: 1 }}>
          {t('EditPostPage:Score')}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          disabled={updateScore.isPending}
          onClick={() => updateScore.mutate()}
        >
          {t('Save')}
        </Button>
      </Box>
      <ScoreTextField
        fullWidth
        margin="dense"
        label={'Valor'}
        value={score}
        onChange={setScore}
      />
    </form>
  )
}
