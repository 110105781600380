import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { GetEntryVersionsPageResult } from '@thehive/cms-management-api'
import { FormattedDate } from '../../../../components'

export type EntryVersionsListProps = {
  entryVersions?: GetEntryVersionsPageResult[]
}

export function EntryVersionsList (props: EntryVersionsListProps) {
  const { entryVersions } = props

  return (
    <List disablePadding>
      {entryVersions?.map(entryVersion => (
        <ListItem key={entryVersion.id}>
          <ListItemText
            primary={`v${entryVersion.versionNumber}`}
            secondary={<FormattedDate date={entryVersion.createdAt} format="Pp" />}
          />
        </ListItem>
      ))}
    </List>
  )
}
