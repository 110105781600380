import { CreateUserForm } from './components/CreateUserForm'
import { Container, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function CreateUserPage () {
  const { t } = useTranslation()

  return (
    <Container maxWidth="xs">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateUserPage:Create user')}
        </Typography>
      </Toolbar>
      <Paper sx={{ p: 2 }}>
        <CreateUserForm />
      </Paper>
    </Container>
  )
}
