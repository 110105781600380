import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ptBR } from '@mui/material/locale'
import { PropsWithChildren } from 'react'
import { customThemeOptions } from './custom-theme-options'

export type CustomThemeProviderProps = PropsWithChildren

export function CustomThemeProvider (props: CustomThemeProviderProps) {
  const theme = createTheme(customThemeOptions, ptBR)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}
