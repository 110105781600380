import { useQuery } from '@tanstack/react-query'
import { useClient } from '.'

export function useOrganization (slug: string) {
  const client = useClient()

  const getOrganization = useQuery({
    queryKey: ['getOrganizationBySlug', slug],
    queryFn: ({ signal }) => client.organizations.getBySlug(slug, { signal })
  })

  return getOrganization
}
