import { useMutation } from '@tanstack/react-query'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { GetEntryFieldsPageResult } from '@thehive/cms-management-api'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'

export type DeleteEntryFieldsDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  entryFields: GetEntryFieldsPageResult[]
}

export function DeleteEntryFieldsDialog (props: DeleteEntryFieldsDialogProps) {
  const { open, onClose, entryFields } = props
  const { t } = useTranslation()
  const client = useClient()
  const deleteEntryFields = useMutation({
    mutationKey: ['deleteEntryFields'],
    mutationFn: async (entryFields: GetEntryFieldsPageResult[]) => {
      try {
        await client.entryFields.delete({
          entryFieldIds: entryFields.map(entryField => entryField.id)
        })
        enqueueSnackbar(t('EntryTypePage:Entry fields deleted'), { variant: 'success' })
        props.onSuccess()
        props.onClose()
      } catch {
        enqueueSnackbar(t('Unexpected error'), { variant: 'success' })
      }
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t('EntryTypePage:Delete entry fields')}
      </DialogTitle>
      <DialogContent>
        {t('This action is irreversible')}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={deleteEntryFields.isPending}
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={deleteEntryFields.isPending}
          onClick={() => deleteEntryFields.mutate(entryFields)}
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
