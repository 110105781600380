import { GetEntryVersionsPageQuery } from '@thehive/cms-management-api'
import { useClient } from './use-client'
import { useQuery } from '@tanstack/react-query'

export function useEntryVersionsPage (params: GetEntryVersionsPageQuery = {}) {
  const client = useClient()

  const getEntryVersionsPage = useQuery({
    queryKey: ['getEntryVersionsPage', params],
    queryFn: ({ signal }) => client.entryVersions.getPage(params, signal)
  })

  return getEntryVersionsPage
}
