import Container from '@mui/material/Container'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAssetsPage, useEnvironment } from '../../../hooks'
import { AssetPreview, Breadcrumb, DataTable } from '../../../components'
import { Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import prettyBytes from 'pretty-bytes'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
}

const defaultPageNumber = 0
const defaultPageSize = 10

export function EnvironmentAssetsPage () {
  const { t } = useTranslation()
  const { organizationSlug, spaceSlug, environmentSlug } = useParams<ParamsType>()
  const [searchParams, setSearchParams] = useSearchParams()
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const getAssetsPage = useAssetsPage({
    environment: environmentSlug,
    space: spaceSlug,
    organization: organizationSlug,
    pageNumber: Number(searchParams.get('pageNumber')) || defaultPageNumber,
    pageSize: Number(searchParams.get('pageSize')) || defaultPageSize
  })

  return (
    <Container maxWidth="md">
      <Breadcrumb
        loading={getEnvironment.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          { label: getEnvironment.data?.space.organization.name, to: `/${organizationSlug}` },
          { label: getEnvironment.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
          { label: getEnvironment.data?.name, to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}` },
          { label: t('Assets') }
        ]}
      />
      <Toolbar disableGutters>
        <Typography variant="h1">{t('Assets')}</Typography>
      </Toolbar>

      <Paper sx={{ mb: 2 }}>
        <DataTable
          getRowKey={row => row.id}
          loading={getAssetsPage.isLoading}
          rows={getAssetsPage.data?.data}
          cols={[
            {
              header: t('Name'),
              render: row => <AssetPreview asset={row} />
            },
            {
              header: t('Size'),
              render: row => row.size ? prettyBytes(row.size) : '-',
              width: 120
            }
          ]}
          pageNumber={Number(searchParams.get('pageNumber')) || defaultPageNumber}
          pageSize={Number(searchParams.get('pageSize')) || defaultPageSize}
          totalSize={getAssetsPage.data?.totalSize}
          onPageNumberChange={pageNumber => setSearchParams({ pageNumber: pageNumber.toString() })}
          onPageSizeChange={pageSize => setSearchParams({ pageSize: pageSize.toString() })}
        />
      </Paper>
    </Container>
  )
}
