import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './locales/en-US.json'
import ptBR from './locales/pt-BR.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      'pt-BR': ptBR,
      'en-US': enUS
    },
    defaultNS: 'common',
    lng: 'pt-BR',
    fallbackLng: 'en-US',
    nsSeparator: ':',
    interpolation: {
      escapeValue: false
    }
  })
