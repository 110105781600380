import { useMemo } from 'react'
import { DataTable, DataTableCol } from '../../../components/DataTable'
import { useAuthentication, useOrganizationsPage } from '../../../hooks'
import { GetOrganizationsPageResult } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'
import { Container, Link, Toolbar, Typography } from '@mui/material'
import { RouterButton } from '../../../components/RouterButton'
import { useNavigate } from 'react-router-dom'

const getRowKey = (row: GetOrganizationsPageResult) => row.id

const getRowHref = (row: GetOrganizationsPageResult) => `/${row.slug}`

export function OrganizationsIndexPage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authentication = useAuthentication()
  const getOrganizationsPage = useOrganizationsPage({})

  const cols = useMemo(
    () => [
      {
        header: t('Name'),
        render: row => (
          <Link
            href={getRowHref(row)}
            onClick={event => event.preventDefault()}
          >
            {row.name}
          </Link>
        )
      }
    ] as DataTableCol<GetOrganizationsPageResult>[],
    [t]
  )

  return (
    <Container maxWidth="md">
      <Toolbar disableGutters>
        <Typography variant="h1" sx={{ flexGrow: 1 }}>
          {t('Organizations')}
        </Typography>
        {authentication.isAdmin && (
          <RouterButton to="/create-organization" variant="contained">
            {t('OrganizationsIndexPage:New organization')}
          </RouterButton>
        )}
      </Toolbar>
      <DataTable
        loading={getOrganizationsPage.isLoading}
        getRowKey={getRowKey}
        rows={getOrganizationsPage.data?.data}
        cols={cols}
        onRowClick={row => navigate(getRowHref(row))}
      />
    </Container>
  )
}
