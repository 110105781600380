import { useQuery } from '@tanstack/react-query'
import { useClient } from '.'

export function useWebsitePage (
  organizationSlug: string,
  websiteSlug: string,
  websitePageSlug: string
) {
  const client = useClient()

  const getWebsitePage = useQuery({
    queryKey: ['getWebsitePageBySlug', organizationSlug, websiteSlug, websitePageSlug],
    queryFn: ({ signal }) => client.websitePages.getBySlug(organizationSlug, websiteSlug, websitePageSlug, signal)
  })

  return getWebsitePage
}
