import { Autocomplete, TextField } from '@mui/material'
import { usePostTypesPage } from '../../hooks'
import { GetPostTypeResult, GetPostTypesPageQuery } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'

export type PostTypeAutocompleteProps = {
  value: GetPostTypeResult | null
  onChange: (value: GetPostTypeResult | null) => void
  query?: GetPostTypesPageQuery
  disabled?: boolean
}

export function PostTypeAutocomplete (props: PostTypeAutocompleteProps) {
  const { t } = useTranslation()
  const getPostTypes = usePostTypesPage({ pageSize: 1_000, ...props.query })

  return (
    <Autocomplete
      disabled={props.disabled}
      sx={{ minWidth: 140 }}
      value={props.value}
      onChange={(_, value) => props.onChange(value)}
      loading={getPostTypes.isLoading}
      options={getPostTypes.data?.data || []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          label={t('Type')}
          size="small"
        />
      )}
    />
  )
}
