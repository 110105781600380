import { useParams } from 'react-router-dom'
import { EditEntriesForm } from './components/EditEntriesForm'
import { Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEntry, useEntryType, useEntryVersion, useEntryVersionsPage, useEnvironment } from '../../../hooks'
import { EntryVersionsList } from './components/EntryVersionsList'
import { Breadcrumb, EntryStatusChip } from '../../../components'
import { EditEntryVersionAssetsForm } from './components/EditEntryVersionAssetsForm'
import { EditEntryStatusForm } from './components/EditEntryStatusForm'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  entryTypeSlug: string
  entryId: string
}

export function EditEntriesPage () {
  const { t } = useTranslation(['common', 'CreateSpacePage'])
  const { organizationSlug, spaceSlug, environmentSlug, entryTypeSlug, entryId } = useParams<ParamsType>()
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const getEntryVersions = useEntryVersionsPage({ entryId })
  const getEntryType = useEntryType(organizationSlug!, spaceSlug!, entryTypeSlug!)
  const getEntry = useEntry(entryId!)
  const getEntryVersion = useEntryVersion(getEntry.data?.lastVersionId)

  return (
    <Container maxWidth="md">
      <Breadcrumb
        loading={getEnvironment.isLoading || getEntryType.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          {
            label: getEnvironment.data?.space.organization.name,
            to: `/${organizationSlug}`
          },
          {
            label: getEnvironment.data?.space.name,
            to: `/${organizationSlug}/${spaceSlug}`
          },
          {
            label: getEnvironment.data?.name,
            to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}`
          },
          {
            label: getEntryType.data?.name,
            to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}/${entryTypeSlug}`
          },
          { label: t('Edit') }
        ]}
      />
      <Toolbar disableGutters>
        <Typography variant="h1">{t('Entry:Edit entry')}</Typography>
        {getEntry.data && <EntryStatusChip sx={{ ml: 2 }} status={getEntry.data.status} />}
      </Toolbar>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Paper sx={{ p: 2 }}>
            {getEntryVersion.data && (
              <EditEntriesForm
                entryTypeSlug={entryTypeSlug!}
                organizationSlug={organizationSlug!}
                environmentSlug={environmentSlug!}
                spaceSlug={spaceSlug!}
                entryId={entryId!}
                entryVersion={getEntryVersion.data}
                onSuccess={() => getEntryVersion.refetch()}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          {getEntry.data && (
            <Paper sx={{ mb: 2, p: 2 }}>
              <EditEntryStatusForm
                entry={getEntry.data}
                onSuccess={() => getEntry.refetch()}
              />
            </Paper>
          )}
          {getEntryVersion.data && (
            <EditEntryVersionAssetsForm
              entryVersion={getEntryVersion.data}
              onSuccess={() => getEntryVersion.refetch()}
            />
          )}
          <Paper>
            <EntryVersionsList
              entryVersions={getEntryVersions.data?.data}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
