import { GetEntryTypesPageQuery } from '@thehive/cms-management-api'
import { useClient } from './use-client'
import { useQuery } from '@tanstack/react-query'

export function useEntryTypesPage (params: GetEntryTypesPageQuery = {}) {
  const client = useClient()

  const getEntryTypesPage = useQuery({
    queryKey: ['getEntryTypesPage', params],
    queryFn: ({ signal }) => client.entryTypes.getPage(params, signal)
  })

  return getEntryTypesPage
}
