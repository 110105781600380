import { useQuery } from '@tanstack/react-query'
import { GetWebsitePagesPageQuery } from '@thehive/cms-management-api'
import { useClient } from './use-client'

export function useWebsitePagesPage (params: GetWebsitePagesPageQuery = {}) {
  const client = useClient()

  const getWebsitePagesPage = useQuery({
    queryKey: ['getWebsitePagesPage', params],
    queryFn: ({ signal }) => client.websitePages.getPage(params, signal)
  })

  return getWebsitePagesPage
}
