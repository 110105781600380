import { GetOrganizationResult } from '@thehive/cms-management-api'
import { Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

export type CreateWebsiteFormProps = {
  organization: GetOrganizationResult
}

export function CreateWebsiteForm (props: CreateWebsiteFormProps) {
  const { organization } = props
  const navigate = useNavigate()
  const { t } = useTranslation(['CreateWebsitePage'])
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      organizationId: organization.id,
      name: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field'))
    }),
    onSubmit: async values => {
      try {
        await client.websites.create(values)
        enqueueSnackbar(t('CreateWebsitePage:Website created'), { variant: 'success' })
        navigate(`/${organization.slug}/websites`)
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateWebsitePage:Website already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        margin="dense"
        label={t('Name')}
        name='name'
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.name && formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ' '}
      />
      <Button
        sx={{ mt: 1 }}
        type="submit"
        fullWidth
        size="large"
        variant="contained"
      >
        {t('Confirm')}
      </Button>
    </form>
  )
}
