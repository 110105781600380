import { Chip, ChipProps } from '@mui/material'
import { PostStatus } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'

export type PostStatusChipProps = ChipProps & {
  status: PostStatus
}

const colorByStatus: Record<PostStatus, ChipProps['color']> = {
  Draft: 'info',
  Review: 'warning',
  Published: 'success',
  Archived: 'error'
}

export function PostStatusChip (props: PostStatusChipProps) {
  const { t } = useTranslation()
  const { status, ...chipProps } = props

  return (
    <Chip
      label={t(`PostStatus ${status}`, status)}
      color={colorByStatus[status]}
      {...chipProps}
    />
  )
}
