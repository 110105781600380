import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import { GetEntryFieldResult } from '@thehive/cms-management-api'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'

export type EditEntryFieldFormProps = {
  entryField: GetEntryFieldResult
  onSuccess?: () => void
}

const checkboxes = [
  { name: 'isLocalizable', label: 'CreateEntryFieldPage:Is localizable' },
  { name: 'isTitle', label: 'CreateEntryFieldPage:Is title' },
  { name: 'isRequiredField', label: 'CreateEntryFieldPage:Is required field' },
  { name: 'isUnique', label: 'CreateEntryFieldPage:Is unique' },
  { name: 'isMultiple', label: 'CreateEntryFieldPage:Is multiple' },
  { name: 'isPublic', label: 'CreateEntryFieldPage:Is public' },
  { name: 'isIndexable', label: 'CreateEntryFieldPage:Is indexable' },
  { name: 'isVisible', label: 'CreateEntryFieldPage:Is visible' }
] as const

export function EditEntryFieldForm (props: EditEntryFieldFormProps) {
  const { entryField } = props
  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: entryField.name,
      description: entryField.description || '',
      isLocalizable: entryField.isLocalizable,
      isTitle: entryField.isTitle,
      isRequiredField: entryField.isRequiredField,
      isUnique: entryField.isUnique,
      isMultiple: entryField.isMultiple,
      isPublic: entryField.isPublic,
      isIndexable: entryField.isIndexable,
      isVisible: entryField.isVisible
    },
    onSubmit: async values => {
      try {
        await client.entryFields.update({
          entryTypeId: entryField.entryTypeId,
          data: [
            {
              entryFieldId: entryField.id,
              ...values
            }
          ]
        })
        enqueueSnackbar(t('EditEntryFieldPage:Entry field updated'), { variant: 'success' })
        props.onSuccess?.()
        navigate(-1)
      } catch {
        enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
      }
    }
  })

  const handleCancel = useCallback(() => {
    if (
      !formik.dirty ||
      window.confirm(t('Cancel operation? Any changes will be lost'))
    ) {
      navigate(-1)
    }
  }, [formik.dirty])

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ' '}
      />
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        name="description" // Adicionado
        label={t('Description')}
        value={formik.values.description}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
      />
      <FormGroup sx={{ my: 0 }}>
        {checkboxes.map(checkbox => (
          <FormControlLabel
            key={checkbox.name}
            control={
              <Checkbox
                color="primary"
                checked={formik.values[checkbox.name]}
                onChange={formik.handleChange}
                name={checkbox.name}
              />
            }
            label={t(checkbox.label)}
          />
        ))}
      </FormGroup>
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
        style={{ marginTop: 10 }}
      >
        {t('Confirm')}
      </Button>
      <Button sx={{ mt: 1 }} fullWidth size="large" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
    </form>
  )
}
