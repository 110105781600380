import { Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { EntryTypeFoldersAutocomplete } from '../../../../components/EntryTypeFoldersAutocomplete'

export type CreateEntryTypeFolderFormProps = {
  spaceId: string
  environmentId: string
  organizationSlug: string
  environmentSlug: string
  spaceSlug: string
  onSuccess?: () => void
}

export function CreateEntryTypeFolderForm (props: CreateEntryTypeFolderFormProps) {
  const { spaceId, environmentId, organizationSlug, spaceSlug } = props

  const { t } = useTranslation()
  const client = useClient()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
      EntryTypeIds: []
    },
    onSubmit: async (values) => {
      const entryTypesIds = values.EntryTypeIds.map((element: any) => element.id)

      try {
        await client.entryTypeFolders.create({
          spaceId,
          environmentId,
          name: values.name,
          entryTypeFoldersCommands: [
            {
              EntryTypeFolderId: '',
              name: values.name,
              EntryTypeIds: entryTypesIds
            }
          ]
        })
        enqueueSnackbar(t('EditEntryTypeFolderPage:folder updated'), { variant: 'success' })
        props.onSuccess?.()
        navigate(-1)
      } catch {
        enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
      }
    }
  })

  const handleCancel = useCallback(() => {
    if (!formik.dirty || window.confirm(t('Cancel operation? Any changes will be lost'))) {
      navigate(-1)
    }
  }, [formik.dirty])

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ' '}
      />
      <EntryTypeFoldersAutocomplete
        margin="dense"
        multiple={true}
        label={t('Referenced type')}
        value={formik.values.EntryTypeIds}
        name="EntryTypeIds"
        onBlur={formik.handleBlur}
        onChange={(value) => formik.setFieldValue('EntryTypeIds', value)}
        query={{
          organization: organizationSlug,
          space: spaceSlug
        }}
      />
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
        style={{ marginTop: 10 }}
      >
        {t('Confirm')}
      </Button>
      <Button sx={{ mt: 1 }} fullWidth size="large" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
    </form>
  )
}
