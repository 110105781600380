import { GetPostVersionResult } from '@thehive/cms-management-api'
import { useState } from 'react'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { TextField } from '@mui/material'

export type EditPostVersionCoverProps = {
  postVersion: GetPostVersionResult
  onSuccess?: () => void
}

export function EditPostVersionCover (props: EditPostVersionCoverProps) {
  const { postVersion } = props
  const { t } = useTranslation()
  const client = useClient()
  const [file, setFile] = useState<File | null>(null)
  const [caption, setCaption] = useState<string>(postVersion.cover?.caption ?? '')

  const updateCover = useMutation({
    mutationKey: ['updatePostVersionCover'],
    mutationFn: async () => {
      if (caption && postVersion.cover) await client.assets.updateAssetCaption(postVersion.cover.id, { caption })
      if (file) {
        await client.postVersions.updateCover(postVersion.id, { file })
        props.onSuccess?.()
      }
    }
  })

  const deleteCover = useMutation({
    mutationKey: ['deletePostVersionCover'],
    mutationFn: async () => {
      if (postVersion.cover) {
        await client.postVersions.deleteCover(postVersion.id, postVersion.cover.id)
        props.onSuccess?.() // Atualiza a UI após a deleção
      }
    }
  })

  return (
    <>
      <Box display="flex">
        <Typography gutterBottom sx={{ flexGrow: 1 }}>
          {t('EditPostPage:Cover image')}
        </Typography>
        <Box>
          <Button
            size="small"
            variant="outlined"
            disabled={updateCover.isPending}
            onClick={() => updateCover.mutate()}
          >
            {t('Save')}
          </Button>
        </Box>
      </Box>

      {postVersion.cover?.url && (
        <Box display="flex" alignItems="center" mb={2}>
          <Box
            component="img"
            sx={{ p: 1, display: 'block', margin: '0 auto', maxWidth: '100%' }}
            src={postVersion.cover.url}
          />
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => deleteCover.mutate()}
            disabled={deleteCover.isPending}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      <input
        type="file"
        onChange={event => {
          const file = event.target.files?.[0]
          if (file) {
            setFile(file)
          }
        }}
      />
      <TextField
        fullWidth
        margin="normal"
        multiline
        rows={2}
        label={t('EditPostPage:Caption')}
        name="title"
        value={caption}
        onChange={e => setCaption(e.target.value)}
      />
    </>
  )
}
