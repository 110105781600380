import { useParams } from 'react-router-dom'
import { CreateEntriesForm } from './components/CreateEntriesForm'
import { Container, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function CreateEntriesPage () {
  const { t } = useTranslation(['common', 'CreateSpacePage'])
  const { organizationSlug, spaceSlug, environmentSlug, entryTypeSlug } =
    useParams<{
      organizationSlug: string
      spaceSlug: string
      environmentSlug: string
      entryTypeSlug: string
    }>()

  return (
    <Container maxWidth="sm">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateEntryPage:Create entry')}
        </Typography>
      </Toolbar>
      <CreateEntriesForm
        organizationSlug={organizationSlug!}
        spaceSlug={spaceSlug!}
        environmentSlug={environmentSlug!}
        entryTypeSlug={entryTypeSlug!}
      />
    </Container>
  )
}
