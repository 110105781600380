import { Alert, Button } from '@mui/material'

type ErrorAlertProps = {
  errorMessage: string
  actionMessage: string
  onClick: () => void
}

export function ErrorAlert (props: ErrorAlertProps) {
  const { errorMessage, actionMessage, onClick } = props

  return (
    <Alert
      sx={{ my: 1 }}
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={onClick}>
          {actionMessage}
        </Button>
      }
    >
      {errorMessage}
    </Alert>
  )
}
