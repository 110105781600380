import { GetPostResult, PostStatus } from '@thehive/cms-management-api'
import { useMutation } from '@tanstack/react-query'
import { useClient } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

export type EditPostStatusFormProps = {
  post: GetPostResult
  onSuccess: (status: PostStatus) => void
}

export function EditPostStatusForm (props: EditPostStatusFormProps) {
  const { post } = props
  const { t } = useTranslation()
  const client = useClient()
  const updateStatus = useMutation({
    mutationKey: ['updatePostStatus', post],
    mutationFn: async (status: PostStatus) => {
      await client.posts.updateStatuses({
        status,
        postIds: [post.id]
      })
      enqueueSnackbar(t('EditPostPage:Post updated'), { variant: 'success' })
      props.onSuccess(status)
    }
  })

  return (
    <>
      <Typography gutterBottom>{t('Status')}</Typography>
      <Box display="flex" gap={1} mb={1}>
        {post.status === 'Archived' && (
          <Button
            size="small"
            variant="outlined"
            disabled={updateStatus.isPending}
            onClick={() => updateStatus.mutate('Draft')}
          >
            {t('EditPostPage:Unarchive')}
          </Button>
        )}

        {post.status === 'Published' && (
          <Button
            size="small"
            variant="outlined"
            disabled={updateStatus.isPending}
            onClick={() => updateStatus.mutate('Draft')}
          >
            {t('EditPostPage:Unpublish')}
          </Button>
        )}

        {post.status === 'Draft' && (
          <Button
            size="small"
            variant="outlined"
            disabled={updateStatus.isPending}
            onClick={() => updateStatus.mutate('Published')}
          >
            {t('EditPostPage:Publish')}
          </Button>
        )}

        {post.status !== 'Archived' && (
          <Button
            size="small"
            variant="outlined"
            disabled={updateStatus.isPending}
            onClick={() => updateStatus.mutate('Archived')}
          >
            {t('EditPostPage:Archive')}
          </Button>
        )}
      </Box>
    </>
  )
}
