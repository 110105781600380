import { useQuery } from '@tanstack/react-query'
import { useClient } from '.'
import { GetAssetsPageQuery } from '@thehive/cms-management-api'

export function useAssetsPage (params: GetAssetsPageQuery) {
  const client = useClient()

  const getAssetsPage = useQuery({
    queryKey: ['getAssetsPage', params],
    queryFn: ({ signal }) => client.assets.getAssetsPage(params, signal)
  })

  return getAssetsPage
}
