import { Chip, ChipProps } from '@mui/material'
import { EntryStatus } from '@thehive/cms-management-api'
import { useTranslation } from 'react-i18next'

export type EntryStatusChipProps = ChipProps & {
  status: EntryStatus
}

const colorByStatus: Record<EntryStatus, ChipProps['color']> = {
  Draft: 'info',
  Published: 'success',
  Archived: 'error'
}

export function EntryStatusChip (props: EntryStatusChipProps) {
  const { t } = useTranslation()
  const { status, ...chipProps } = props

  return (
    <Chip
      label={t(`EntryStatus ${status}`, status)}
      color={colorByStatus[status]}
      {...chipProps}
    />
  )
}
