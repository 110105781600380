import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useEntryType (
  organizationSlug: string,
  spaceSlug: string,
  entryTypeSlug: string
) {
  const client = useClient()

  const getEntryType = useQuery({
    queryKey: ['getEntryTypeBySlug', organizationSlug, spaceSlug, entryTypeSlug],
    queryFn: ({ signal }) => client.entryTypes.getBySlug(organizationSlug, spaceSlug, entryTypeSlug, signal)
  })

  return getEntryType
}
