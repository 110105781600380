import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { GetPostResult } from '@thehive/cms-management-api'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

export type EditPostIsSponsoredFormProps = {
  post: GetPostResult
}

export function EditPostIsSponsoredForm (props: EditPostIsSponsoredFormProps) {
  const { post } = props
  const { t } = useTranslation()
  const client = useClient()
  const [isSponsored, setIsSponsored] = useState(post.isSponsored)

  useEffect(() => {
    setIsSponsored(post.isSponsored)
  }, [post.isSponsored])

  const updateIsSponsored = useMutation({
    mutationKey: ['updatePostIsSponsored', post],
    mutationFn: async () => {
      try {
        await client.posts.updateIsSponsored(post.id, { isSponsored: isSponsored || false })
        enqueueSnackbar(t('EditPostPage:Post updated'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(t('EditPostPage:Error updating post'), { variant: 'error' })
      }
    }
  })

  return (
    <form>
      <Box display="flex" sx={{
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSponsored || false}
              onChange={() => setIsSponsored(prev => !prev)}
              name={t('EditPostPage:Sponsored')}
            />
          }
          label={t('EditPostPage:Sponsored')}
        />
        <Button
          size="small"
          variant="outlined"
          disabled={updateIsSponsored.isPending}
          onClick={() => updateIsSponsored.mutate()}
        >
          {t('Save')}
        </Button>
      </Box>
    </form>
  )
}
