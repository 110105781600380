import { CmsManagementApi } from '@thehive/cms-management-api'
import { useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { appRoutes } from './app-routes'
import { AppProviders } from './app-providers'
import './i18n'

const apiClient = new CmsManagementApi({
  baseURL: import.meta.env.VITE_API_BASE_URL
})

const router = createBrowserRouter(appRoutes)

export type AppProps = {
  apiClient?: CmsManagementApi
  router?: ReturnType<typeof createBrowserRouter>
}

export function App (props: AppProps) {
  useEffect(() => {
    if (import.meta.env.DEV && !import.meta.env.TEST) {
      // @ts-expect-error - Clarity is added dynamically by script
      window.clarity('stop')
    }
  })

  return (
    <AppProviders
      apiClient={props.apiClient || apiClient}
    >
      <RouterProvider router={props.router || router} />
    </AppProviders>
  )
}
