import { Button, Container, Divider, Link, Paper, Skeleton, Toolbar, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { GetEntryFieldsPageResult } from '@thehive/cms-management-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEntryType } from '../../../hooks/use-entry-type'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb, DataTable, RouterButton, RouterLink } from '../../../components'
import { useEntryFieldsPage } from '../../../hooks/use-entry-fields-page'
import { useAuthentication, useClient } from '../../../hooks'
import { DeleteEntryFieldsDialog } from './components/DeleteEntryFieldsDialog'
import { ErrorAlert } from '../../../components/ErrorAlert/ErrorAlert'

export function EntryTypePage () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const client = useClient()
  const [selection, setSelection] = useState<Record<string, GetEntryFieldsPageResult>>({})
  const [deleteEntryFieldDialogOpen, setDeleteEntryFieldDialogOpen] = useState(false)
  const { organizationSlug, spaceSlug, entryTypeSlug } = useParams<{
    organizationSlug: string
    spaceSlug: string
    entryTypeSlug: string
  }>()
  const authentication = useAuthentication()

  const getEntryType = useEntryType(organizationSlug!, spaceSlug!, entryTypeSlug!)
  const getEntryFieldsPage = useEntryFieldsPage({
    organization: organizationSlug, space: spaceSlug, entryType: entryTypeSlug, pageSize: 1000
  })
  const selectionLength = Object.keys(selection).length

  const isNotFound = client.isNotFound(getEntryType.error)

  return (
    <Container maxWidth="md">
      {getEntryType.isError && !isNotFound && (
        <ErrorAlert
          onClick={() => getEntryType.refetch()}
          errorMessage={t('EntryTypePage:Error on searching entry type')}
          actionMessage={t('Try again')}
        />
      )}

      {isNotFound && (
        <ErrorAlert
          onClick={() => navigate(-1)}
          errorMessage={t('EntryTypePage:Entry type not found')}
          actionMessage={t('Return')}
        />
      )}

      {!getEntryType.isError && (
        <>
          <Breadcrumb
            loading={getEntryType.isLoading}
            data={[
              { label: t('Home'), to: '/' },
              { label: getEntryType.data?.space.organization.name, to: `/${organizationSlug}` },
              { label: getEntryType.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
              { label: getEntryType.data?.name }
            ]}
          />

          <Toolbar disableGutters>
            <Typography variant="h1">
              {getEntryType.isLoading && <Skeleton width="280px" height={48} />}
              {getEntryType.data?.name}
            </Typography>
          </Toolbar>

          <DeleteEntryFieldsDialog
            open={deleteEntryFieldDialogOpen}
            onClose={() => setDeleteEntryFieldDialogOpen(false)}
            onSuccess={() => {
              setSelection({})
              getEntryFieldsPage.refetch()
            }}
            entryFields={Object.values(selection)}
          />

          <Paper>
            <Toolbar>
              {selectionLength === 0 && (
                <Typography variant="h2" sx={{ flexGrow: 1 }}>
                  {t('Entry fields')}
                </Typography>
              )}
              {selectionLength > 0 && (
                <Typography sx={{ flexGrow: 1 }}>
                  {t('EntryTypePage:Entry fields selected', { count: selectionLength })}
                </Typography>
              )}
              {selectionLength === 0 && (
                authentication.isAdmin && (
                  <RouterButton
                    size="small"
                    variant="outlined"
                    to={`/${organizationSlug}/${spaceSlug}/type/${entryTypeSlug}/create-field`}
                  >
                    {t('EntryTypePage:New entry field')}
                  </RouterButton>
                )
              )}
              {selectionLength > 0 && (
                <>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => setDeleteEntryFieldDialogOpen(true)}
                    startIcon={<DeleteIcon />}
                  >
                    {t('Delete')}
                  </Button>
                </>
              )}
            </Toolbar>
            <Divider />
            <DataTable
              loading={getEntryFieldsPage.isLoading}
              selection={selection}
              onSelectionChange={setSelection}
              getRowKey={(row) => row.id}
              onRowClick={row => navigate(`/${organizationSlug}/${spaceSlug}/type/${entryTypeSlug}/${row.slug}`)}
              key={'entry-types-table'}
              rows={getEntryFieldsPage.data?.data || []}
              cols={[
                {
                  header: t('Name'),
                  render: (row) => (
                    <Link
                      href={`/${organizationSlug}/${spaceSlug}/type/${entryTypeSlug}/${row.slug}`}
                      onClick={event => event.preventDefault()}
                    >
                      {row.name}
                    </Link>
                  ),
                  width: 130
                },
                {
                  header: t('Type'),
                  render: row => row.referencedEntryType
                    ? (
                      <RouterLink to={`/${organizationSlug}/${spaceSlug}/type/${row.referencedEntryType.slug}`}>
                        {row.referencedEntryType.name}
                      </RouterLink>
                      )
                    : row.valueType,
                  width: 130
                },
                {
                  header: t('EntryTypePage:Is localizable'),
                  render: (row) => row.isLocalizable ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                },
                {
                  header: t('EntryTypePage:Is required'),
                  render: (row) => row.isRequiredField ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                },
                {
                  header: t('EntryTypePage:Is unique'),
                  render: (row) => row.isUnique ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                },
                {
                  header: t('EntryTypePage:Is multiple'),
                  render: (row) => row.isMultiple ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                },
                {
                  header: t('EntryTypePage:Is public'),
                  render: (row) => row.isPublic ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                },
                {
                  header: t('EntryTypePage:Is indexable'),
                  render: (row) => row.isIndexable ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                },
                {
                  header: t('EntryTypePage:Is visible'),
                  render: (row) => row.isVisible ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
                  width: 90
                }
              ]}
            />
          </Paper>
        </>
      )}
    </Container>
  )
}
