import { Container, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CreateOrganizationForm } from './components/CreateOrganizationForm'

export function CreateOrganizationPage () {
  const { t } = useTranslation(['common', 'CreateOrganizationPage'])

  return (
    <Container maxWidth="xs">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateOrganizationPage:Create organization')}
        </Typography>
      </Toolbar>

      <Paper sx={{ p: 2 }}>
        <CreateOrganizationForm />
      </Paper>
    </Container>
  )
}
