import { Container, Paper, Skeleton, Toolbar, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useEntryField } from '../../../hooks'
import { EditEntryFieldForm } from './components/EditEntryFieldForm'
import { useTranslation } from 'react-i18next'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  entryTypeSlug: string
  entryFieldSlug: string
}

export function EditEntryFieldPage () {
  const { organizationSlug, spaceSlug, entryTypeSlug, entryFieldSlug } = useParams<ParamsType>()
  const { t } = useTranslation()
  const getEntryField = useEntryField(organizationSlug, spaceSlug, entryTypeSlug, entryFieldSlug)

  return (
    <Container maxWidth="sm">
      <Toolbar disableGutters>
        <Typography variant="h1" component="div" sx={{ flexGrow: 1 }}>
          {getEntryField.isLoading && <Skeleton width={180} />}
          {!getEntryField.isLoading && t('EditEntryFieldPage:Edit entry field')}
        </Typography>
      </Toolbar>
      {getEntryField.data && (
        <Paper sx={{ p: 2 }}>
          <EditEntryFieldForm
            entryField={getEntryField.data}
            onSuccess={() => getEntryField.refetch()}
          />
        </Paper>
      )}
    </Container>
  )
}
