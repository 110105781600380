import { LinearProgress } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuthentication } from '../../hooks'
import { useEffect } from 'react'

export function AuthenticationGuard () {
  const authentication = useAuthentication()
  const navigate = useNavigate()
  const location = useLocation()

  const { loading, authenticationData } = authentication

  useEffect(
    () => {
      if (!loading && !authenticationData) {
        if (location.pathname !== '/sign-in' && location.pathname !== '/') {
          navigate(`/sign-in?redirect=${location.pathname}`)
        } else {
          navigate('/sign-in')
        }
      }
    },
    [loading, authenticationData]
  )

  return (
    <>
      {loading && <LinearProgress />}
      {authenticationData && <Outlet />}
    </>
  )
}
