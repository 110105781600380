import { GetEntriesPageQuery, GetEntriesPageResult } from '@thehive/cms-management-api'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { ReactNode } from 'react'
import { useEntriesPage } from '../../hooks'

export type EntriesAutocompleteProps = {
  multiple?: boolean
  label?: ReactNode
  value: GetEntriesPageResult | GetEntriesPageResult[]
  onChange: (value: GetEntriesPageResult | GetEntriesPageResult[] | null) => void
  query?: GetEntriesPageQuery
  disabled?: boolean
  size?: 'small' | 'medium'
  name?: string
  margin?: TextFieldProps['margin']
  onBlur?: AutocompleteProps<GetEntriesPageResult[], false, false, false>['onBlur']
  error?: TextFieldProps['error']
  helperText?: TextFieldProps['helperText']
}

export function EntriesAutocomplete (props: EntriesAutocompleteProps) {
  const getEntryTypes = useEntriesPage({ pageSize: 1_000, ...props.query })

  return (
    <Autocomplete
      multiple={props.multiple}
      disabled={props.disabled}
      sx={{ minWidth: 140, marginTop: 2 }}
      value={props.value}
      onBlur={props.onBlur}
      onChange={(_, value) => props.onChange(value)}
      loading={getEntryTypes.isLoading}
      options={getEntryTypes.data?.data || []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          name={props.name}
          margin={props.margin}
          label={props.label}
          size={props.size}
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  )
}
