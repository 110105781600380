import { Container, Paper, Skeleton, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useTag } from '../../../hooks/use-tag'
import { EditTagForm } from './components/EditTagForm'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  tagSlug: string
  entryId: string
}

export function EditTagPage () {
  const { t } = useTranslation()
  const { organizationSlug, spaceSlug, environmentSlug, tagSlug } = useParams<ParamsType>()
  const getTag = useTag(organizationSlug!, spaceSlug!, environmentSlug!, tagSlug!)

  return (
    <Container maxWidth="sm">
      <Toolbar disableGutters>
        <Typography variant="h1" component="div" sx={{ flexGrow: 1 }}>
          {getTag.isLoading && <Skeleton width={180} />}
          {!getTag.isLoading && t('EditTagPage:Edit tag')}
        </Typography>
      </Toolbar>
      { getTag.data && (
        <Paper sx={{ p: 2 }}>
          <EditTagForm
            tag={getTag.data}
          />
        </Paper>
      )}
    </Container>
  )
}
