import { GetEntryTypeFolderPageQuery } from '@thehive/cms-management-api'
import { useClient } from './use-client'
import { useQuery } from '@tanstack/react-query'

export function useEntryTypeFoldersPage (params: GetEntryTypeFolderPageQuery) {
  const client = useClient()

  const getEntryTypesPage = useQuery({
    queryKey: ['getEntryTypeFoldersPage', params],
    queryFn: ({ signal }) => client.entryTypeFolders.getPage(params, signal)
  })

  return getEntryTypesPage
}
