export function formatScore (value: string): string {
  if (value === '0') return value
  if (value === '1') return value

  let formatted = ''
  const array = value.split('')
  if (array.length === 2) {
    formatted = array[0] + '.' + array[1]
  }

  if (array.length === 3) {
    formatted = array[0] + '.' + array[1] + array[2]
  }

  return formatted
}
