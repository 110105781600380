import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { GetAssetResult } from '@thehive/cms-management-api'
import { FileTypeIcon } from '../FileTypeIcon'
import Link from '@mui/material/Link'

export type AssetPreviewProps = {
  asset?: GetAssetResult | null
  onDeleteClick?: (asset: GetAssetResult) => void
}

export function AssetPreview (props: AssetPreviewProps) {
  const { asset, onDeleteClick } = props

  if (!asset) {
    return <></>
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <FileTypeIcon type={asset.type} />
      <Link href={asset.url ?? ''} target="_blank">
        {asset.fileName}
      </Link>
      {onDeleteClick && (
        <IconButton size="small" onClick={() => onDeleteClick(asset)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}
