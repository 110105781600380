import { useParams } from 'react-router-dom'
import { CreateEntryTypeForm } from './components/CreateEntryTypeForm'
import { Container, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSpace } from '../../../hooks'

export function CreateEntryTypePage () {
  const { t } = useTranslation(['common', 'CreateEntryTypePage'])
  const { organizationSlug, spaceSlug } = useParams<{ organizationSlug: string, spaceSlug: string }>()
  const getSpace = useSpace(organizationSlug!, spaceSlug!)

  return (
    <Container maxWidth="xs">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateEntryTypePage:Create entry type')}
        </Typography>
      </Toolbar>
      {getSpace.isLoading && <LinearProgress />}
      {getSpace.data && (
        <Paper sx={{ p: 2 }}>
          <CreateEntryTypeForm space={getSpace.data} />
        </Paper>
      )}
    </Container>
  )
}
