import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { stripText } from './strip-text'
import { formatScore } from './format-score'

export type ScoreTextFieldProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: string
  onChange: (value: string) => void
}

export function ScoreTextField ({ value, onChange, ...props }: ScoreTextFieldProps) {
  const formattedValue = formatScore(value) || value

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(stripText(event.target.value, 3))
  }

  return (
    <TextField
      {...props}
      value={formattedValue}
      onChange={handleChange}
    />
  )
}
