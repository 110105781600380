import { Box, Divider, FormLabel, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { ReactNode } from 'react'
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'

export type MultipleTextFieldProps = {
  label: ReactNode
  helperText?: string
  value: string[]
  onChange: (value: string[]) => void
}

function reorder<T> (items: T[], from: number, to: number): T[] {
  const result = Array.from(items)

  const [removed] = result.splice(from, 1)

  result.splice(to, 0, removed)

  return result
}

export function MultipleTextField (props: MultipleTextFieldProps) {
  const { label, helperText, value, onChange } = props
  const { t } = useTranslation()

  const handleDragEnd: OnDragEndResponder = (result) => {
    if (result.destination) {
      const from = result.source.index
      const to = result.destination.index

      onChange(reorder(value, from, to))
    }
  }

  const handleAddClick = () => {
    onChange([...value, ''])
  }

  const handleRemoveClick = (index: number) => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <FormLabel>{label}</FormLabel>
        <FormLabel sx={{ fontSize: 12 }}>{helperText}</FormLabel>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Table size="small" sx={{ mb: 4 }}>
        <TableHead>
        </TableHead>
        <Droppable droppableId="droppable">
          {provided => (
            <TableBody
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {value.map((item, index) => (
                <Draggable
                  index={index}
                  key={'droppable' + index.toString()}
                  draggableId={'droppable' + index.toString()}
                >
                  {(provided, snapshot) => (
                    <TableRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <TableCell
                        padding="none"
                        width={32}
                        {...provided.dragHandleProps}
                      >
                        <DragIndicatorIcon fontSize="small" color="action" />
                      </TableCell>
                      <TableCell padding="none">
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          margin="none"
                          fullWidth
                          variant="standard"
                          value={item}
                          onChange={(event) => {
                            const newValue = [...value]
                            newValue[index] = event.target.value
                            onChange(newValue)
                          }}
                        />
                      </TableCell>
                      <TableCell
                        width={32}
                      >
                        {!snapshot.isDragging && (
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveClick(index)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
        <TableFooter>
          <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleAddClick}>
            <TableCell padding="normal" colSpan={3}>
              {t('Add')}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </DragDropContext>
  )
}
