import { useParams } from 'react-router-dom'
import { CreateSpaceForm } from './components/CreateSpaceForm'
import { Container, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useOrganization } from '../../../hooks'

export function CreateSpacePage () {
  const { t } = useTranslation(['common', 'CreateSpacePage'])
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const getOrganization = useOrganization(organizationSlug!)

  return (
    <Container maxWidth="xs">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateSpacePage:Create space')}
        </Typography>
      </Toolbar>
      {getOrganization.isLoading && <LinearProgress />}
      {getOrganization.data && (
        <Paper sx={{ p: 2 }}>
          <CreateSpaceForm organization={getOrganization.data} />
        </Paper>
      )}
    </Container>
  )
}
