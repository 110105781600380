import { useParams } from 'react-router-dom'
import { useOrganization } from '../../../hooks'
import { Container, LinearProgress, Paper, Toolbar, Typography } from '@mui/material'
import { CreateWebsiteForm } from './components/CreateWebsiteForm'
import { useTranslation } from 'react-i18next'

export function CreateWebsitePage () {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { t } = useTranslation()
  const getOrganization = useOrganization(organizationSlug!)

  return (
    <Container maxWidth="xs">
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('CreateWebsitePage:Create website')}
        </Typography>
      </Toolbar>
      {getOrganization.isLoading && <LinearProgress />}
      {getOrganization.data && (
        <Paper sx={{ p: 2 }}>
          <CreateWebsiteForm
            organization={getOrganization.data}
          />
        </Paper>
      )}
    </Container>
  )
}
