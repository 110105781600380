import Container from '@mui/material/Container'
import { ForgotPasswordForm } from './components/ForgotPasswordForm.js'
import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RouterLink } from '../../../components/index.js'

export function ForgotPasswordPage () {
  const { t } = useTranslation()

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Paper sx={{ p: 4 }}>
        <Typography variant="h1" textAlign="center" gutterBottom>
          {t('ForgotPasswordPage:Forgot password')}
        </Typography>
        <ForgotPasswordForm />
        <Box sx={{ mt: 2 }}>
          <RouterLink to="/sign-in">
            {t('ForgotPasswordPage:Back to sign in')}
          </RouterLink>
        </Box>
      </Paper>
    </Container>
  )
}
