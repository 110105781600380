import {
  Checkbox,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableProps,
  TableRow
} from '@mui/material'
import { ChangeEvent, MouseEvent, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTableRow } from './DataTableRow'

export type DataTableCol<T> = {
  header: ReactNode
  render: (row: T) => ReactNode
  align?: TableCellProps['align']
  padding?: TableCellProps['padding']
  width?: string | number
}

export type DataTableProps<T> = {
  getRowKey: (row: T) => string | number
  cols: DataTableCol<T>[]
  rows?: T[]
  stickyHeader?: boolean
  size?: TableProps['size']
  TableContainerProps?: TableContainerProps

  // Events
  onRowClick?: (row: T) => void

  // Loading
  loading?: boolean
  skeletonRows?: number

  // Selection
  selection?: Record<string | number, T>
  onSelectionChange?: (
    update: (previousSelection: Record<string | number, T>) => Record<string | number, T>
  ) => void

  // Pagination
  pageNumber?: number
  pageSize?: number
  totalSize?: number
  pageSizeOptions?: number[]
  onPageNumberChange?: (pageNumber: number) => void
  onPageSizeChange?: (pageSize: number) => void
}

export function DataTable<T> (props: DataTableProps<T>) {
  const { t } = useTranslation(['DataTable'])
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null)
  const { getRowKey, rows, cols, loading } = props
  const { selection, onSelectionChange } = props
  const { pageNumber, pageSize, totalSize, pageSizeOptions, onPageNumberChange, onPageSizeChange } = props

  const handleSelectionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selected: boolean, row: T, index: number) => {
      const shiftKey = Boolean((event.nativeEvent as unknown as MouseEvent).shiftKey)

      setLastSelectedIndex(index)

      onSelectionChange?.(previousSelection => {
        const newSelection = { ...previousSelection }

        if (shiftKey && lastSelectedIndex !== null) {
          const minIndex = Math.min(index, lastSelectedIndex)
          const maxIndex = Math.max(index, lastSelectedIndex)

          rows?.slice(minIndex, maxIndex + 1).forEach(row => {
            if (selected) {
              newSelection[getRowKey(row)] = row
            } else {
              delete newSelection[getRowKey(row)]
            }
          })
        } else {
          if (selected) {
            newSelection[getRowKey(row)] = row
          } else {
            delete newSelection[getRowKey(row)]
          }
        }

        return newSelection
      })
    },
    [onSelectionChange, lastSelectedIndex]
  )

  return (
    <>
      <TableContainer {...props.TableContainerProps}>
        <Table stickyHeader={props.stickyHeader} size={props.size}>
          <TableHead>
            <TableRow>
              {selection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size={props.size}
                    disabled={loading}
                    checked={Object.values(selection).length > 0}
                    indeterminate={(
                      !rows?.every(row => Boolean(selection[getRowKey(row)])) &&
                      rows?.some(row => Boolean(selection[getRowKey(row)]))
                    )}
                    onChange={() => {
                      if (rows?.some(row => Boolean(selection[getRowKey(row)]))) {
                        onSelectionChange?.(() => ({}))
                      } else {
                        onSelectionChange?.(() => Object.fromEntries(
                          new Map(rows?.map(row => [getRowKey(row), row]))
                        ))
                      }
                    }}
                  />
                </TableCell>
              )}
              {cols.map((col, colIndex) => (
                <TableCell key={colIndex} width={col.width} size="medium">
                  {col.header}
                </TableCell>
              ))}
            </TableRow>
            {loading && rows && rows.length > 0 && (
              <TableRow>
                <TableCell padding="none" colSpan={cols.length + 1}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {(!rows || rows?.length === 0) && loading && (
              [...Array(props.skeletonRows || 5)].map((_, index) => (
                <TableRow key={index}>
                  {selection && (
                    <TableCell key={index} padding="checkbox">
                      <Checkbox disabled size={props.size} />
                    </TableCell>
                  )}
                  {cols.map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
            {rows?.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={1000} size="medium">
                  {t('DataTable:No records found')}
                </TableCell>
              </TableRow>
            )}
            {rows?.map((row, index) => (
              <DataTableRow
                key={getRowKey(row)}
                index={index}
                row={row}
                cols={cols}
                size={props.size}
                selected={selection && Boolean(selection[getRowKey(row)])}
                onSelectedChange={selection && handleSelectionChange}
                hover={Boolean(props.onRowClick)}
                onClick={() => props.onRowClick?.(row)}
                sx={{ cursor: props.onRowClick ? 'pointer' : 'default' }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pageNumber !== undefined && pageSize !== undefined && totalSize !== undefined && onPageNumberChange && onPageSizeChange && (
        <TablePagination
          component="div"
          page={pageNumber}
          rowsPerPage={pageSize}
          rowsPerPageOptions={pageSizeOptions}
          count={totalSize}
          onPageChange={(_, pageNumber) => onPageNumberChange(pageNumber)}
          onRowsPerPageChange={event => onPageSizeChange(Number(event.target.value))}
        />
      )}
    </>
  )
}
