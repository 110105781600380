import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useClient, useEntry } from '../../../../hooks'
import { EditEntryValuesCommand, GetEntryVersionResult } from '@thehive/cms-management-api'
import { AnyObject } from 'yup'
import { enqueueSnackbar } from 'notistack'
import { DatePicker } from '@mui/x-date-pickers'
import { Editor } from '@tinymce/tinymce-react'
import isDate from 'date-fns/isDate'
import { EntriesAutocomplete } from '../../../../components/EntriesAutocomplete'
import { MultipleTextField } from '../../EditEntryPageV2/components/MultipleTextField'
import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import { dracula } from '@uiw/codemirror-theme-dracula'
import * as yup from 'yup'
import { isEmpty } from 'lodash'
import RequiredFieldTooltip, { renderTooltipContent } from '../../../../components/RequiredFieldTooltip/RequiredFieldTooltip'

export type EditEntriesFormProps = {
  entryVersion: GetEntryVersionResult
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  entryTypeSlug: string
  entryId: string
  onSuccess?: () => void
}

interface ReferencedEntryType {
  id: string
  name: string
  slug: string
}

interface EntryField {
  id: string
  name: string
  valueType: string
  isMultiple: boolean
  isRequiredField: boolean
  description?: string
  referencedEntryType?: ReferencedEntryType | ReferencedEntryType[]
}

function buildValidationSchema (entryFields: EntryField[]) {
  const schemaFields: Record<string, any> = {}
  entryFields.forEach((field) => {
    if (field.isRequiredField && field.valueType !== 'Media') {
      if (field.valueType === 'Reference' && field.isMultiple) {
        schemaFields[field.id] = yup.array().of(yup.object().shape({
          id: yup.string().required('ID é obrigatório')
        })).min(1, 'Pelo menos uma referência é necessária')
      } else if (field.valueType === 'Reference' && !field.isMultiple) {
        schemaFields[field.id] = yup.object().shape({
          id: yup.string().required('ID é obrigatório')
        })
      } else {
        schemaFields[field.id] = yup.string().required('Este campo é obrigatório')
      }
    }
  })

  return yup.object().shape(schemaFields)
}

function valuesToField (data: GetEntryVersionResult) {
  const fieldValues: Record<string, unknown> = {}

  data.entry.entryType.entryFields?.forEach(entryField => {
    if (entryField.isMultiple) {
      fieldValues[entryField.id] = []
    } else {
      fieldValues[entryField.id] = ''
    }
  })

  for (const field of data.entry.entryType.entryFields) {
    const values = data.entryValues
      .filter(value => value.entryField.id === field.id)
      .sort((a, b) => a.order - b.order)

    if (field.isMultiple && field.valueType === 'Reference') {
      fieldValues[field.id] = values.map(value => value.entry)
    } else if (field.valueType === 'Reference') {
      fieldValues[field.id] = values[0]?.entry
    } else if (field.isMultiple) {
      fieldValues[field.id] = values.map(value => value.value)
    } else {
      fieldValues[field.id] = values[0]?.value
    }
  }

  return fieldValues
}

export function EditEntriesForm (props: EditEntriesFormProps) {
  const { entryVersion } = props
  const { organizationSlug, spaceSlug, environmentSlug, entryTypeSlug, entryId } = props
  const getEntry = useEntry(entryId)
  const entryVersionId = getEntry.data?.lastVersionId
  const environmentId = getEntry.data?.environmentId
  const validationSchema = buildValidationSchema(entryVersion.entry.entryType.entryFields as any)

  const navigate = useNavigate()
  const client = useClient()

  const formik = useFormik<AnyObject>({
    initialValues: {
      entryVersionId: entryVersion.id,
      ...valuesToField(entryVersion)
    },
    validationSchema,
    onSubmit: async (rawValues) => {
      const entryValueCommands =
        entryVersion.entry.entryType.entryFields
          .filter((field) => field.valueType !== 'Media')
          .map((field) => {
            const rawValue = rawValues[field.id]
            let value: string = ''
            let values: string[] | null = null
            let entryIds: string[] | null = null
            let entryId: string | null = null

            if (field.valueType === 'Date') {
              if (isDate(rawValue)) {
                value = rawValue.toISOString()
              } else {
                const date = new Date(rawValue)
                value = date.toISOString()
              }
            } else if (field.valueType === 'RichText') {
              value = rawValues[field.id]
            } else if (field.isMultiple && field.valueType === 'Reference') {
              entryIds = rawValues[field.id].map((entry: { id: string }) => entry.id)
            } else if (field.valueType === 'Reference') {
              entryId = rawValues[field.id]?.id || null
            } else if (field.isMultiple) {
              values = rawValues[field.id]
            } else {
              value = rawValues[field.id]?.toString()
            }

            return {
              entryFieldId: field.id,
              locale: 'pt-BR',
              value,
              values,
              entryIds,
              entryId
            }
          })
          .filter((cmd) => (cmd.value !== undefined) || cmd.entryIds !== null || cmd.values !== null || cmd.entryId !== null)

      const submitData: EditEntryValuesCommand = {
        entryVersionId: entryVersionId!,
        entryValueCommands
      }

      try {
        await client.entryValues.edit(submitData)
        props.onSuccess?.()
        enqueueSnackbar(t('CreateEntryFieldPage:Entry created'), { variant: 'success' })
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('CreateEntryFieldPage:Entry already exists'), {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(t('CreateEntryFieldPage:Error creating entry'), {
            variant: 'error'
          })
        }
      }
    }
  })

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (formik.dirty && !isEmpty(formik.errors)) {
        const message = t('Cancel operation? Any changes will be lost')
        event.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [formik.dirty, formik.errors, t])

  const handleCancel = useCallback(() => {
    const formChanged = formik.dirty
    const formHasErrors = !isEmpty(formik.errors)
    const confirmLeave = window.confirm(t('Cancel operation? Any changes will be lost'))

    if (!formik.dirty || confirmLeave) {
      console.log('confirmLeave', confirmLeave)
      navigate(`/${organizationSlug}/${spaceSlug}/${environmentSlug}/${entryTypeSlug}/`)
    } else if (formChanged && formHasErrors) {
      const confirmLeave = window.confirm(t('Cancel operation? Any changes will be lost'))
      if (confirmLeave) {
        navigate(`/${organizationSlug}/${spaceSlug}/${environmentSlug}/${entryTypeSlug}/`)
      }
    }
  }, [formik.dirty, navigate, organizationSlug, spaceSlug, entryTypeSlug])

  const renderField = (field: GetEntryVersionResult['entry']['entryType']['entryFields'][number]) => {
    const hasError = Boolean(formik.touched[field.id] && formik.errors[field.id])

    const getDescriptionHelperText = () => {
      return field.description ? field.description : ' '
    }

    switch (field.valueType) {
      case 'Text':
        if (field.isMultiple) {
          return (
            <MultipleTextField
              key={field.id}
              label={field.name}
              helperText={getDescriptionHelperText()}
              value={formik.values[field.id] as string[]}
              onChange={(value) => {
                formik.setFieldValue(field.id, value)
              }}
            />
          )
        } else {
          return (
            <RequiredFieldTooltip
              title={renderTooltipContent(t('Required field'))}
              open={hasError}
              arrow
              placement="right"
              disableHoverListener={!hasError}
            >
              <TextField
                key={field.id}
                margin="normal"
                disabled={formik.isSubmitting}
                fullWidth
                name={field.id}
                label={field.name}
                value={formik.values[field.id]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                style={{ marginTop: 10 }}
                helperText={getDescriptionHelperText()}
              />
            </RequiredFieldTooltip>
          )
        }
      case 'RichText':
        return (
          <RequiredFieldTooltip
            title={renderTooltipContent(t('Required field'))}
            open={hasError}
            arrow
            placement="right"
            disableHoverListener={!hasError}
          >
            <div key={field.id}>
              <div>{field.name}</div>
              <Editor
                tinymceScriptSrc={
                  'https://cdn.jsdelivr.net/npm/tinymce@5.9.2/tinymce.min.js'
                }
                value={formik.values[field.id]}
                onEditorChange={(content) =>
                  formik.setFieldValue(field.id, content)}
                init={{
                  height: 400,
                  menubar: true,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'preview',
                    'help',
                    'wordcount'
                  ],
                  file_picker_callback: async function (cb) {
                    const input = document.createElement('input')
                    input.setAttribute('type', 'file')
                    input.setAttribute('accept', 'image/*')

                    input.onchange = async function () {
                      let file: any

                      if (input.files) {
                        file = input.files[0]
                      }

                      const uploaded = await client.assets.createAsset({
                        environmentId: environmentId || '',
                        file,
                        isPublic: true
                      })

                      cb(uploaded.url || '', { title: uploaded.fileName })
                    }

                    input.click()
                  },
                  toolbar:
                    'image | undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  font_formats:
                    "DM Sans='DM Sans';Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino;Times New Roman=times new roman,times",
                  content_style:
                    "@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');" +
                    'body { font-family:DM Sans, Helvetica, Arial, sans-serif; font-size:14px }'
                }}
              />
              <div className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-kcm06l-MuiFormHelperText-root">{getDescriptionHelperText()}</div>
            </div>
          </RequiredFieldTooltip>
        )
      case 'Number':
        return (
          <RequiredFieldTooltip
            title={renderTooltipContent(t('Required field'))}
            open={hasError}
            arrow
            placement="right"
            disableHoverListener={!hasError}
          >
            <TextField
              key={field.id}
              margin="normal"
              type="number"
              disabled={formik.isSubmitting}
              fullWidth
              name={field.id}
              label={field.name}
              value={formik.values[field.id]}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              style={{ marginTop: 10 }}
              helperText={getDescriptionHelperText()}
            />
          </RequiredFieldTooltip>
        )
      case 'Date': {
        const dateValue = formik.values[field.id]
          ? new Date(formik.values[field.id])
          : null
        return (
          <RequiredFieldTooltip
            title={renderTooltipContent(t('Required field'))}
            open={hasError}
            arrow
            placement="right"
            disableHoverListener={!hasError}
          >
            <div>
              <DatePicker
                key={field.id}
                label={field.name}
                value={dateValue}
                onChange={(newValue) => {
                  formik.setFieldValue(field.id, newValue)
                }}
              />
              <div className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-kcm06l-MuiFormHelperText-root">{getDescriptionHelperText()}</div>
            </div>
          </RequiredFieldTooltip>
        )
      }
      case 'Boolean':
        return (
          <RequiredFieldTooltip
            title={renderTooltipContent(t('Required field'))}
            open={hasError}
            arrow
            placement="right"
            disableHoverListener={!hasError}
          >
            <div>
              <FormControlLabel
                key={field.id}
                control={
                  <Checkbox
                    checked={formik.values[field.id] === 'true'}
                    onChange={(event) => {
                      formik.setFieldValue(
                        field.id,
                        event.target.checked.toString()
                      )
                    }}
                    name={field.id}
                  />
                }
                label={field.name}
              />
              <div className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-kcm06l-MuiFormHelperText-root">{getDescriptionHelperText()}</div>
            </div>
          </RequiredFieldTooltip>
        )
      case 'Reference':
        return (
          <RequiredFieldTooltip
            title={renderTooltipContent(t('Required field'))}
            open={hasError}
            arrow
            placement="right"
            disableHoverListener={!hasError}
          >
            <div style={{ marginBottom: 15 }}>
              <EntriesAutocomplete
                key={field.id}
                multiple={field.isMultiple}
                label={field.name}
                value={formik.values[field.id]}
                onChange={(value) => {
                  formik.setFieldValue(field.id, value)
                }}
                query={{
                  organization: organizationSlug,
                  space: spaceSlug,
                  environment: environmentSlug,
                  entryType: field.referencedEntryType?.slug
                }}
                helperText={getDescriptionHelperText()}
              />
            </div>
          </RequiredFieldTooltip>
        )
      case 'JSON':
        return (
          <RequiredFieldTooltip
            title={renderTooltipContent(t('Required field'))}
            open={hasError}
            arrow
            placement="right"
            disableHoverListener={!hasError}
          >
            <div key={field.id}>
              <div>{field.name}</div>
              <CodeMirror
                key={field.id}
                value={formik.values[field.id]}
                theme={dracula}
                height="400px"
                extensions={[json()]}
                onChange={(value) => {
                  formik.setFieldValue(field.id, value)
                }}
              />
              <div className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-kcm06l-MuiFormHelperText-root">{getDescriptionHelperText()}</div>
            </div>
          </RequiredFieldTooltip>
        )
      default:
        return null
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {entryVersion.entry.entryType.entryFields.map(renderField)}
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
        style={{ marginTop: 10 }}
      >
        {t('Save')}
      </Button>
      <Button sx={{ mt: 1 }} fullWidth size="large" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
    </form>
  )
}
