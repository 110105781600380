import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useSpace (
  organizationSlug: string,
  spaceSlug: string
) {
  const client = useClient()

  const getSpace = useQuery({
    queryKey: ['getSpaceBySlug', organizationSlug, spaceSlug],
    queryFn: ({ signal }) => client.spaces.getBySlug(organizationSlug, spaceSlug, signal)
  })

  return getSpace
}
