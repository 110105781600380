import { AuthenticationData } from '@thehive/cms-management-api'
import { createContext } from 'react'

export type AuthenticationContextValue = {
  isAdmin?: boolean | null
  authenticationData: AuthenticationData | null
  setAuthenticationData: (value: AuthenticationData | null) => void
  error: unknown
  loading: boolean
}

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  authenticationData: null,
  setAuthenticationData: () => {},
  error: undefined,
  loading: false
})
