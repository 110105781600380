import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'
import { Pagination } from '@thehive/cms-management-api'

export function usePostVersionsPage (postId: string, params: Pagination = {}) {
  const client = useClient()

  const getPostVersionsPage = useQuery({
    queryKey: ['getPostVersionsPage', postId, params],
    queryFn: ({ signal }) => client.posts.getVersionsPage(postId, params, signal)
  })

  return getPostVersionsPage
}
