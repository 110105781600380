import { Alert, Box, Button, Container, Toolbar, Typography } from '@mui/material'
import { DataTable, FormattedDate } from '../../../components'
import { useApiKeysPage, useClient } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { CreateApiKeyForm } from './components/CreateApiKeyForm'
import { CreateApiKeyResult, GetApiKeyResult } from '@thehive/cms-management-api'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

export function ApiKeysIndexPage () {
  const { t } = useTranslation()
  const client = useClient()
  const [lastResult, setLastResult] = useState<CreateApiKeyResult | null>(null)
  const getApiKeysPage = useApiKeysPage({ pageSize: 1_000 })
  const deleteApiKey = useMutation({
    mutationKey: ['deleteApiKey'],
    mutationFn: async (row: GetApiKeyResult) => {
      await client.apiKeys.delete(row.id)
      getApiKeysPage.refetch()
      if (row.id === lastResult?.id) {
        setLastResult(null)
      }
    }
  })

  return (
    <Container maxWidth="sm">
      <Toolbar disableGutters>
        <Typography variant="h1">API Keys</Typography>
      </Toolbar>

      <Box sx={{ my: 4 }}>
        <CreateApiKeyForm
          onSuccess={(result) => {
            setLastResult(result)
            getApiKeysPage.refetch()
          }}
        />
      </Box>

      {lastResult && (
        <Alert severity="success" onClose={() => setLastResult(null)}>
          {lastResult?.plainTextValue}
        </Alert>
      )}

      <DataTable
        loading={getApiKeysPage.isLoading}
        getRowKey={row => row.id}
        rows={getApiKeysPage.data?.data}
        cols={[
          { header: t('Description'), render: row => row.description },
          { header: t('Creation date'), render: row => <FormattedDate date={row.createdAt} /> },
          {
            header: t('Actions'),
            render: row => (
              <Button
                disabled={deleteApiKey.isPending}
                size="small"
                variant="outlined"
                onClick={() => deleteApiKey.mutate(row)}
              >
                {t('Delete')}
              </Button>
            )
          }
        ]}
      />
    </Container>
  )
}
