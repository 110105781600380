import { Button, Paper, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { GetAssetResult, GetEntryVersionResult } from '@thehive/cms-management-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { AssetPreview } from '../../../../components'

export type EditEntryVersionAssetsFormProps = {
  entryVersion: GetEntryVersionResult
  onSuccess?: () => void
}

export function EditEntryVersionAssetsForm (props: EditEntryVersionAssetsFormProps) {
  const { entryVersion } = props
  const { t } = useTranslation()
  const client = useClient()
  const [files, setFiles] = useState<Record<string, File>>({})
  const entryFields = entryVersion.entry.entryType.entryFields
  const mediaFields = entryFields.filter(entryField => entryField.valueType === 'Media')

  const setEntryVersionValue = useMutation({
    mutationKey: ['setEntryVersionValue'],
    mutationFn: async (entryFieldId: string) => {
      const entryField = entryFields.find(entryField => entryField.id === entryFieldId)

      if (entryField?.isMultiple) {
        await client.entryVersions.createValues(
          entryVersion.id,
          entryFieldId,
          {
            locale: null,
            files: [files[entryFieldId]]
          }
        )
      } else {
        await client.entryVersions.setValue(
          entryVersion.id,
          entryFieldId,
          {
            locale: null,
            value: null,
            file: files[entryFieldId]
          }
        )
      }
      props.onSuccess?.()
      enqueueSnackbar(t('EditEntryPage:Asset updated'), { variant: 'success' })
    }
  })
  const deleteAsset = useMutation({
    mutationFn: async (asset: GetAssetResult) => {
      const entryValue = entryVersion.entryValues.find(entryValue => entryValue.asset?.id === asset.id)

      if (entryValue) {
        await client.entryValues.delete(entryValue.id)
        props.onSuccess?.()
        enqueueSnackbar(t('EditEntryPage:Asset deleted'), { variant: 'success' })
      }
    }
  })

  return (
    <>
      {mediaFields.map(entryField => (
        <Paper key={entryField.id} sx={{ p: 2, mb: 2 }}>
          <Box display="flex" mb={1}>
            <Typography component="span" sx={{ flexGrow: 1 }}>
              {entryField.name}
            </Typography>
            <Box>
              <Button
                disabled={setEntryVersionValue.isPending}
                size="small"
                variant="outlined"
                onClick={() => {
                  setEntryVersionValue.mutate(entryField.id)
                }}
              >
                {t('Save')}
              </Button>
            </Box>
          </Box>
          <input
            type="file"
            onChange={event => {
              const file = event.target.files?.[0]
              if (file) {
                setFiles(files => {
                  const newFiles = { ...files }

                  newFiles[entryField.id] = file

                  return newFiles
                })
              }
            }}
          />
          <Box py={1}>
            {entryVersion.entryValues
              .filter(entryValue => entryValue.entryField.id === entryField.id)
              .map(entryValue => (
                <AssetPreview
                  key={entryValue.id}
                  asset={entryValue.asset}
                  onDeleteClick={(asset) => deleteAsset.mutate(asset)}
                />
              ))}
          </Box>
        </Paper>
      ))}
    </>
  )
}
