import { useClient } from './use-client'
import { useQuery } from '@tanstack/react-query'
import { GetEntryFieldsPageQuery } from '@thehive/cms-management-api'

export function useEntryFieldsPage (params: GetEntryFieldsPageQuery = {}) {
  const client = useClient()

  const getEntryFieldsPage = useQuery({
    queryKey: ['getEntryFieldsPage', params],
    queryFn: ({ signal }) => client.entryFields.getPage(params, signal)
  })

  return getEntryFieldsPage
}
