import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { GetEnvironmentResult } from '@thehive/cms-management-api'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'

export type CreateTagDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  environment: GetEnvironmentResult
}

export function CreateTagDialog (props: CreateTagDialogProps) {
  const { environment, open, onClose, onSuccess } = props
  const { t } = useTranslation()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      environmentId: environment.id,
      name: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field'))
    }),
    onSubmit: async (values, helpers) => {
      try {
        await client.tags.create(values)
        onClose()
        onSuccess()
        enqueueSnackbar(t('EnvironmentTagsPage:Tag created'), { variant: 'success' })
        helpers.resetForm()
      } catch (error) {
        if (client.isConflict(error)) {
          enqueueSnackbar(t('EnvironmentTagsPage:Tag already exists'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })

  const handleClose = () => {
    onClose()
    formik.resetForm()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {t('EnvironmentTagsPage:Create tag')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              margin="dense"
              disabled={formik.isSubmitting}
              fullWidth
              size="small"
              name="name"
              label={t('Name')}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name || ' '}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={formik.isSubmitting}>
            {t('Cancel')}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
