import { GetPostResult } from '@thehive/cms-management-api'
import { useFormik } from 'formik'
import { useClient } from '../../../../hooks'
import { PostTypeAutocomplete } from '../../../../components'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'

export type EditPostTypeFormProps = {
  post: GetPostResult
  spaceId: string
}

export function EditPostTypeForm (props: EditPostTypeFormProps) {
  const { t } = useTranslation()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      postType: props.post.type
    },
    onSubmit: async values => {
      try {
        await client.posts.updateTypes({
          postTypeId: values.postType?.id || null,
          postIds: [props.post.id]
        })
        enqueueSnackbar(t('EditPostPage:Post type updated'), { variant: 'success' })
      } catch {
        enqueueSnackbar(t('EditPostPage:Error updating post type'), { variant: 'error' })
      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography sx={{ flexGrow: 1 }}>
          {t('Post type')}
        </Typography>
        <div>
          <Button
            type="submit"
            size="small"
            variant="outlined"
            sx={{ mt: 1 }}
          >
            {t('Save')}
          </Button>
        </div>
      </Box>
      <PostTypeAutocomplete
        query={{ spaceId: props.spaceId }}
        value={formik.values.postType}
        onChange={value => formik.setFieldValue('postType', value)}
      />
    </form>
  )
}
