import { Box, Container, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SignInForm } from './components/SignInForm'
import { useAuthentication } from '../../../hooks'
import { RouterLink } from '../../../components'

export function SignInPage () {
  const { t } = useTranslation(['common', 'SignInPage'])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { authenticationData } = useAuthentication()

  useEffect(
    () => {
      if (authenticationData) {
        navigate(searchParams.get('redirect') || '/')
      }
    },
    [authenticationData]
  )

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Paper sx={{ px: 2, py: 8, width: '100%' }}>
        <Typography variant="h1" textAlign="center">
          Sistema de gestão de conteúdo
        </Typography>
        <Container maxWidth="xs" sx={{ my: 4 }}>
          <SignInForm />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <RouterLink to="/forgot-password">
              {t('SignInPage:Forgot password')}
            </RouterLink>
          </Box>
        </Container>
      </Paper>
    </Container>
  )
}
