import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'
import { GetEnvironmentsPageQuery } from '@thehive/cms-management-api'

export function useEnvironmentsPage (params: GetEnvironmentsPageQuery) {
  const client = useClient()

  const getEnvironmentsPage = useQuery({
    queryKey: ['getEnvironmentsPage', params],
    queryFn: ({ signal }) => client.environments.getPage(params, signal)
  })

  return getEnvironmentsPage
}
