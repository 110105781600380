import Button, { ButtonProps } from '@mui/material/Button'
import { Link } from 'react-router-dom'

export type RouterButtonProps = Omit<ButtonProps, 'component'> & {
  to: string
}

export function RouterButton (props: RouterButtonProps) {
  return (
    <Button component={Link} {...(props as any)} />
  )
}
