import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useClient } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

export type ResetPasswordFormProps = {
  token: string
}

export function ResetPasswordForm (props: ResetPasswordFormProps) {
  const { t } = useTranslation()
  const { token } = props
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      newPassword: ''
    },
    validationSchema: yup.object().shape({
      newPassword: yup.string()
        .required(t('Required field'))
        .min(6, t('Password must have at least {{length}} characters', { length: 6 }))
    }),
    onSubmit: async values => {
      try {
        await client.authentication.resetPassword({ newPassword: values.newPassword, token })
        enqueueSnackbar(t('ResetPasswordPage:Password updated'), { variant: 'success' })
        navigate('/sign-in')
      } catch {
        enqueueSnackbar(t('ResetPasswordPage:Invalid link'), { variant: 'error' })
      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        type="password"
        name="newPassword"
        value={formik.values.newPassword}
        label={t('New password')}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
        helperText={(formik.touched.newPassword && formik.errors.newPassword) || ' '}
      />
      <Button
        sx={{ mt: 1 }}
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
      >
        {t('Confirm')}
      </Button>
    </form>
  )
}
