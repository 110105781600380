import { useNavigate, useParams } from 'react-router-dom'
import {
  useAuthentication,
  useClient,
  useEntryTypesPage,
  useEnvironmentsPage,
  usePostTypesPage,
  useSpace
} from '../../../hooks'
import { Container, Divider, Link, Paper, Skeleton, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../../../components/DataTable'
import {
  GetEntryTypesPageResult,
  GetEnvironmentsPageResult
} from '@thehive/cms-management-api'
import { useCallback, useEffect } from 'react'
import { Breadcrumb, RouterButton } from '../../../components'
import { ErrorAlert } from '../../../components/ErrorAlert/ErrorAlert'

export function SpacePage () {
  const { t } = useTranslation()
  const { organizationSlug, spaceSlug } = useParams<{
    organizationSlug: string
    spaceSlug: string
  }>()
  const client = useClient()
  const navigate = useNavigate()
  const authentication = useAuthentication()

  const getSpace = useSpace(organizationSlug!, spaceSlug!)
  const getEnvironmentsPage = useEnvironmentsPage({
    organization: organizationSlug,
    space: spaceSlug,
    pageSize: 1000
  })
  const getEntryTypesPage = useEntryTypesPage({
    organization: organizationSlug,
    space: spaceSlug,
    pageSize: 1000
  })

  const getPostTypes = usePostTypesPage({ organization: organizationSlug, space: spaceSlug })

  const buildEnvironmentHref = useCallback(
    (row: GetEnvironmentsPageResult) => {
      const space = getSpace.data
      return `/${space?.organization.slug}/${space?.slug}/${row.slug}`
    },
    [getSpace.data]
  )

  const handleEnvironmentRowClick = useCallback(
    (row: GetEnvironmentsPageResult) => navigate(buildEnvironmentHref(row)),
    [buildEnvironmentHref]
  )

  const buildEntryTypeHref = useCallback(
    (row: GetEntryTypesPageResult) => {
      const space = getSpace.data
      return `/${space?.organization.slug}/${space?.slug}/type/${row.slug}/`
    },
    [getSpace.data]
  )

  const handleEntryTypeRowClick = useCallback(
    (row: GetEntryTypesPageResult) => {
      navigate(buildEntryTypeHref(row))
    },
    [buildEntryTypeHref]
  )

  useEffect(() => {
    if (!authentication.isAdmin) {
      navigate(`/${organizationSlug}/${spaceSlug}/main`, { replace: true })
    }
  }, [authentication.isAdmin])

  const isNotFound = client.isNotFound(getSpace.error)

  return (
    <Container maxWidth="md">
      {getSpace.isError && !isNotFound && (
        <ErrorAlert
          onClick={() => getSpace.refetch()}
          errorMessage={t('SpacePage:Error on searching space')}
          actionMessage={t('Try again')}
        />
      )}

      {isNotFound && (
        <ErrorAlert
          onClick={() => navigate(-1)}
          errorMessage={t('SpacePage:Space not found')}
          actionMessage={t('Return')}
        />
      )}

      {!getSpace.isError && (
        <>
          <Breadcrumb
            loading={getSpace.isLoading}
            data={[
              { label: t('Home'), to: '/' },
              { label: getSpace.data?.organization.name, to: `/${organizationSlug}` },
              { label: getSpace.data?.name }
            ]}
          />
          <Toolbar disableGutters>
            <Typography variant="h1">
              {getSpace.isLoading && <Skeleton width="280px" height={48} />}
              {getSpace.data?.name}
            </Typography>
          </Toolbar>

          <Paper>
            <Toolbar>
              <Typography variant="h2" sx={{ flexGrow: 1 }}>{t('Environments')}</Typography>
              {authentication.isAdmin && (
                <RouterButton
                  size="small"
                  variant="outlined"
                  to={`/${organizationSlug}/${spaceSlug}/create-environment`}
                >
                  {t('SpacePage:New environment')}
                </RouterButton>
              )}
            </Toolbar>
            <Divider />
            <DataTable
              skeletonRows={2}
              loading={getEnvironmentsPage.isLoading}
              getRowKey={(row) => row.id}
              onRowClick={(row) => {
                handleEnvironmentRowClick(row)
              }}
              rows={getEnvironmentsPage.data?.data || []}
              cols={[
                {
                  header: t('Name'),
                  render: (row) => (
                    <Link
                      href={buildEnvironmentHref(row)}
                      onClick={(event) => event.preventDefault()}
                    >
                      {row.name}
                    </Link>
                  )
                }
              ]}
            />
          </Paper>

          <Paper sx={{ my: 4 }}>
            <Toolbar>
              <Typography variant="h2" sx={{ flexGrow: 1 }}>
                {t('Entry types')}
              </Typography>
              {authentication.isAdmin && (
                <RouterButton
                  size="small"
                  variant="outlined"
                  to={`/${organizationSlug}/${spaceSlug}/create-entry-type`}
                >
                  {t('SpacePage:New entry type')}
                </RouterButton>
              )}
            </Toolbar>
            <Divider />
            <DataTable
              skeletonRows={2}
              loading={getEntryTypesPage.isLoading}
              getRowKey={(row) => row.id}
              onRowClick={(row) => {
                handleEntryTypeRowClick(row)
              }}
              rows={getEntryTypesPage.data?.data || []}
              cols={[
                {
                  header: t('Name'),
                  render: (row) => (
                    <Link
                      href={buildEntryTypeHref(row)}
                      onClick={(event) => event.preventDefault()}
                    >
                      {row.name}
                    </Link>
                  )
                }
              ]}
            />
          </Paper>

          <Paper>
            <Toolbar>
              <Typography variant="h2" sx={{ flexGrow: 1 }}>
                {t('Post types')}
              </Typography>
              {authentication.isAdmin && (
                <RouterButton
                  size="small"
                  variant="outlined"
                  to={`/${organizationSlug}/${spaceSlug}/create-post-type`}
                >
                  {t('SpacePage:New entry type')}
                </RouterButton>
              )}
            </Toolbar>
            <Divider />
            <DataTable
              skeletonRows={2}
              loading={getPostTypes.isLoading}
              getRowKey={(row) => row.id}
              rows={getPostTypes.data?.data || []}
              cols={[
                {
                  header: t('Name'),
                  render: (row) => (
                    <Typography fontSize="14px">
                      {row.name}
                    </Typography>
                  )
                }
              ]}
            />
          </Paper>

        </>
      )}
    </Container>
  )
}
