import { Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAuthentication, useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'

const fields = [
  { name: 'email', label: 'Email', type: 'text' },
  { name: 'password', label: 'Password', type: 'password' }
] as const

export function SignInForm () {
  const { t } = useTranslation(['common', 'SignInPage'])
  const client = useClient()
  const authentication = useAuthentication()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async values => {
      try {
        const result = await client.authentication.signIn(values)
        authentication.setAuthenticationData(result)
      } catch (error) {
        if (client.isUnauthorized(error)) {
          enqueueSnackbar(t('SignInPage:Invalid credentials'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {fields.map(field => (
        <TextField
          key={field.name}
          fullWidth
          disabled={formik.isSubmitting}
          margin="dense"
          name={field.name}
          type={field.type}
          label={t(field.label)}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(formik.touched[field.name] && formik.errors[field.name])}
          helperText={formik.touched[field.name] && formik.errors[field.name] || ' '}
        />
      ))}
      <Button
        disabled={formik.isSubmitting}
        sx={{ mt: 2 }}
        type="submit"
        fullWidth
        size="large"
        variant="contained"
      >
        {t('SignInPage:Submit')}
      </Button>
    </form>
  )
}
