import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuthentication, useClient, useOrganization, useSpacesPage, useUsersPage } from '../../../hooks'
import { Container, Divider, Link, Paper, Skeleton, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../../../components/DataTable'
import { RouterButton } from '../../../components/RouterButton'
import { useCallback } from 'react'
import { GetSpacesPageResult } from '@thehive/cms-management-api'
import { Breadcrumb } from '../../../components'
import { ErrorAlert } from '../../../components/ErrorAlert/ErrorAlert'

export function OrganizationPage () {
  const { t } = useTranslation(['common', 'OrganizationPage'])
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const client = useClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('tab') || 'spaces'

  const navigate = useNavigate()
  const authentication = useAuthentication()

  const handleSpaceRowClick = useCallback(
    (row: GetSpacesPageResult) => navigate(buildSpaceHref(row)),
    []
  )
  const buildSpaceHref = (space: GetSpacesPageResult) =>
    `/${organizationSlug}/${space.slug}`

  const getOrganization = useOrganization(organizationSlug!)
  const getSpacesPage = useSpacesPage({
    organization: organizationSlug,
    pageSize: 100
  })
  const getUsersPage = useUsersPage({
    organization: organizationSlug,
    pageSize: 100
  })

  const isNotFound = client.isNotFound(getOrganization.error)

  return (
    <Container maxWidth="md">
      {getOrganization.isError && !isNotFound && (
        <ErrorAlert
          onClick={() => getOrganization.refetch()}
          errorMessage={t('OrganizationPage:Error on searching organization')}
          actionMessage={t('Try again')}
        />
      )}

      {isNotFound && (
        <ErrorAlert
          onClick={() => navigate(-1)}
          errorMessage={t('OrganizationPage:Organization not found')}
          actionMessage={t('Return')}
        />
      )}

      {!getOrganization.isError && (
        <>
          <Breadcrumb
            loading={getOrganization.isLoading}
            data={[
              { label: t('Home'), to: '/' },
              { label: getOrganization.data?.name }
            ]}
          />
          <Toolbar disableGutters>
            <Typography variant="h1">
              {getOrganization.isLoading && (
                <Skeleton width="280px" height={48} />
              )}
              {getOrganization.data?.name}
            </Typography>
          </Toolbar>
          <Paper>
            <Tabs
              value={searchParams.get('tab') || 'spaces'}
              onChange={(_, value) => setSearchParams({ tab: value })}
            >
              <Tab value="spaces" label={t('Spaces')} />
              <Tab value="members" label={t('Members')} />
            </Tabs>
            <Divider />

            {tab === 'spaces' && (
              <>
                <Toolbar>
                  <Typography variant="h2" sx={{ flexGrow: 1 }}>
                    {t('Spaces')}
                  </Typography>
                  {authentication.isAdmin && (
                    <RouterButton
                      variant="outlined"
                      size="small"
                      to={`/${organizationSlug}/create-space`}
                    >
                      {t('OrganizationPage:New space')}
                    </RouterButton>
                  )}
                </Toolbar>
                <Divider />
                <DataTable
                  loading={getSpacesPage.isLoading}
                  getRowKey={row => row.id}
                  rows={getSpacesPage.data?.data}
                  onRowClick={handleSpaceRowClick}
                  cols={[
                    {
                      header: t('Name'),
                      render: row => (
                        <Link
                          href={buildSpaceHref(row)}
                          onClick={event => event.preventDefault()}
                        >
                          {row.name}
                        </Link>
                      )
                    }
                  ]}
                />
              </>
            )}

            {tab === 'members' && (
              <>
                <Toolbar>
                  <Typography variant="h2" sx={{ flexGrow: 1 }}>
                    {t('Members')}
                  </Typography>
                </Toolbar>
                <Divider />
                <DataTable
                  loading={getUsersPage.isLoading}
                  getRowKey={row => row.id}
                  rows={getUsersPage.data?.data}
                  cols={[{ header: t('Name'), render: row => row.name }]}
                />
              </>
            )}
          </Paper>
        </>
      )}
    </Container>
  )
}
