import { Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { GetTagResult } from '@thehive/cms-management-api'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
}

type EditTagFormProps = {
  tag: GetTagResult
}

export function EditTagForm (props: EditTagFormProps) {
  const { tag } = props
  const { t } = useTranslation()
  const { organizationSlug, spaceSlug, environmentSlug } = useParams<ParamsType>()
  const client = useClient()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: tag.name
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field'))
    }),
    onSubmit: async values => {
      try {
        const result = await client.tags.update(tag.id, values)
        enqueueSnackbar(t('EditTagPage:Tag updated'), { variant: 'success' })
        navigate(`/${organizationSlug}/${spaceSlug}/${environmentSlug}/tags/${result.slug}`)
      } catch {
        enqueueSnackbar(t('EditTagPage:Error changing tag'), { variant: 'error' })
      }
    }
  })

  const handleCancel = useCallback(() => {
    if (
      !formik.dirty ||
      window.confirm(t('Cancel operation? Any changes will be lost'))
    ) {
      navigate(-1)
    }
  }, [formik.dirty])

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        margin="dense"
        disabled={formik.isSubmitting}
        fullWidth
        name="name"
        label={t('Name')}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name || ' '}
      />
      <Button
        fullWidth
        disabled={formik.isSubmitting}
        type="submit"
        variant="contained"
        size="large"
        style={{ marginTop: 10 }}
      >
        {t('Confirm')}
      </Button>
      <Button sx={{ mt: 1 }} fullWidth size="large" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
    </form>
  )
}
