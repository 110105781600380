import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useEntryField (
  organizationSlug?: string,
  spaceSlug?: string,
  entryTypeSlug?: string,
  entryFieldSlug?: string
) {
  const client = useClient()

  const getEntryField = useQuery({
    queryKey: ['getEntryField', organizationSlug, spaceSlug, entryTypeSlug, entryFieldSlug],
    queryFn: ({ signal }) => client.entryFields.getBySlug(
      organizationSlug!, spaceSlug!, entryTypeSlug!, entryFieldSlug!, signal)
  })

  return getEntryField
}
