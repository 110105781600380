import { Route, RouteObject, createRoutesFromElements } from 'react-router-dom'
import { AuthenticationGuard, Layout, ProfileLayout } from './components'
import { ChangePasswordPage, ForgotPasswordPage, ProfilePage, ResetPasswordPage, SignInPage } from './pages/authentication'
import { IndexPage, NotFoundPage } from './pages/common'
import { CreateEnvironmentPage, EnvironmentAssetsPage, EnvironmentPage, EnvironmentPostsPage, EnvironmentTagsPage } from './pages/environments'
import {
  CreateOrganizationPage,
  OrganizationPage,
  OrganizationsIndexPage
} from './pages/organizations'
import { CreateSpacePage, SpacePage } from './pages/spaces'
import { CreateEntryTypePage, EntryTypePage } from './pages/entry-types'
import { CreateEntryFieldPage, EditEntryFieldPage } from './pages/entry-fields'
import { EntriesIndexPage } from './pages/entries'
import { CreateEntriesPage } from './pages/entries/CreateEntriesPage'
import { EditEntriesPage } from './pages/entries/EditEntriesPage'
import { ApiKeysIndexPage } from './pages/api-keys'
import { EditPostPage } from './pages/posts'
import { CreateUserPage, UsersIndexPage } from './pages/users'
import { CreateWebsitePage, WebsitePage, WebsitesIndexPage } from './pages/websites'
import { WebsitePagePage } from './pages/website-pages'
import { CreatePostTypePage } from './pages/post-types/CreatePostTypePage/CreatePostTypePage'
import { EditTagPage, TagPage } from './pages/tags'
import { CreateEntryTypeFolderPage } from './pages/entry-type-folders'
import { EditEntryTypeFolderPage } from './pages/entry-type-folders/EditEntryTypeFolderPage'

export const appRoutes: RouteObject[] = createRoutesFromElements([
  <Route key={0} path="*" element={<AuthenticationGuard />}>
    <Route path="*" element={<Layout />}>
      <Route path="" element={<IndexPage />} />
      <Route path="organizations" element={<OrganizationsIndexPage />} />
      <Route path=":organizationSlug" element={<OrganizationPage />} />
      <Route path=":organizationSlug/create-space" element={<CreateSpacePage />} />
      <Route path=":organizationSlug/:spaceSlug" element={<SpacePage />} />
      <Route path=":organizationSlug/:spaceSlug/create-environment" element={<CreateEnvironmentPage />} />
      <Route path=":organizationSlug/:spaceSlug/create-entry-type" element={<CreateEntryTypePage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/create-folder" element={<CreateEntryTypeFolderPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/folders/:entryTypeFolderSlug/edit" element={<EditEntryTypeFolderPage />} />
      <Route path=":organizationSlug/:spaceSlug/create-post-type" element={<CreatePostTypePage />} />
      <Route path=":organizationSlug/:spaceSlug/type/:entryTypeSlug" element={<EntryTypePage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug" element={<EnvironmentPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/assets" element={<EnvironmentAssetsPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/posts" element={<EnvironmentPostsPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/tags" element={<EnvironmentTagsPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/tags/:tagSlug" element={<TagPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/tags/:tagSlug/edit" element={<EditTagPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/posts/:postId" element={<EditPostPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/:entryTypeSlug" element={<EntriesIndexPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/:entryTypeSlug/create-entry" element={<CreateEntriesPage />} />
      <Route path=":organizationSlug/:spaceSlug/:environmentSlug/:entryTypeSlug/:entryId" element={<EditEntriesPage />} />
      <Route path=":organizationSlug/:spaceSlug/type/:entryTypeSlug/create-field" element={<CreateEntryFieldPage />} />
      <Route path=":organizationSlug/:spaceSlug/type/:entryTypeSlug/:entryFieldSlug" element={<EditEntryFieldPage />} />
      <Route path=":organizationSlug/create-website" element={<CreateWebsitePage />} />
      <Route path=":organizationSlug/websites" element={<WebsitesIndexPage />} />
      <Route path=":organizationSlug/websites/:websiteSlug" element={<WebsitePage />} />
      <Route path=":organizationSlug/websites/:websiteSlug/:websitePageSlug" element={<WebsitePagePage />} />
      <Route path="create-organization" element={<CreateOrganizationPage />} />

      <Route path="api-keys" element={<ApiKeysIndexPage />} />
      <Route path="create-user" element={<CreateUserPage />} />

      <Route path="profile" element={<ProfileLayout />}>
        <Route path="" element={<ProfilePage />} />
        <Route path="change-password" element={<ChangePasswordPage />} />
      </Route>

      <Route path="users" element={<UsersIndexPage />} />
    </Route>
  </Route>,
  <Route key={1} path="/sign-in" element={<SignInPage />} />,
  <Route key={2} path="/forgot-password" element={<ForgotPasswordPage />} />,
  <Route key={3} path="/reset-password" element={<ResetPasswordPage />} />,
  <Route key={4} path="*" element={<NotFoundPage />} />
])
