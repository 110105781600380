import Link, { LinkProps } from '@mui/material/Link'
import { Link as ReactRouterLink } from 'react-router-dom'

export type RouterLinkProps = LinkProps & {
  to: string
}

export function RouterLink (props: RouterLinkProps) {
  return (
    <Link component={ReactRouterLink} {...props} />
  )
}
