import { useParams } from 'react-router-dom'
import { Box, Container, Grid, Paper, Skeleton, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { useEnvironment, usePost, usePostVersion, usePostVersionsPage } from '../../../hooks'
import { Breadcrumb, PostStatusChip } from '../../../components'
import { useTranslation } from 'react-i18next'
import { EditPostVersionForm } from './components/EditPostVersionForm'
import { PostVersionsList } from './components/PostVersionsList'
import { EditPostVersionCover } from './components/EditPostVersionCover'
import { EditPostTypeForm } from './components/EditPostTypeForm'
import { EditPostVersionMedia } from './components/EditPostVersionMedia'
import { PostTypeConfiguration } from '@thehive/cms-management-api'
import { EditPostStatusForm } from './components/EditPostStatusForm'
import { EditPostVersionThumbnail } from './components/EditPostVersionThumbnail'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { EditPostScoreForm } from './components/EditPostScoreForm'
import { EditPostOrderForm } from './components/EditPostOrderForm'
import { EditPostIsSponsoredForm } from './components/EditPostIsSponsoredForm'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  postId: string
}

export function EditPostPage () {
  const { t } = useTranslation()
  const params = useParams<ParamsType>()
  const getEnvironment = useEnvironment(params.organizationSlug!, params.spaceSlug!, params.environmentSlug!)
  const getPost = usePost(params.postId!)
  const getVersionsPage = usePostVersionsPage(params.postId!, { pageSize: 1_000 })
  const getPostVersion = usePostVersion(getVersionsPage.data?.data[0].id)
  const organization = getEnvironment.data?.space.organization.slug
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <>
      <Container maxWidth="md">
        <Breadcrumb
          loading={getEnvironment.isLoading}
          data={[
            {
              to: `/${params.organizationSlug}`,
              label: getEnvironment.data?.space.organization.name
            },
            {
              to: `/${params.organizationSlug}/${params.spaceSlug}`,
              label: getEnvironment.data?.space.name
            },
            {
              to: `/${params.organizationSlug}/${params.spaceSlug}/${params.environmentSlug}`,
              label: getEnvironment.data?.name
            },
            {
              to: `/${params.organizationSlug}/${params.spaceSlug}/${params.environmentSlug}/posts`,
              label: t('Posts')
            },
            {
              label: t('Edit')
            }
          ]}
        />
        <Toolbar disableGutters>
          <Typography variant="h1"sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
            {getPost.isLoading && <Skeleton width={280} />}
            {!getPost.isLoading && t('EditPostPage:Edit post')}
          </Typography>
          {getPost.data && (
            <>
              <PostStatusChip status={getPost.data?.status} sx={{ ml: 2 }} />
              <Box
                display='flex'
                flexDirection='row'
                sx={{ ml: 'auto' }}
                gap={1}
                title='Visualizações'
              >
                <VisibilityIcon />
                <Typography>{getPost.data?.viewsCount}</Typography>
              </Box>
            </>
          )}

        </Toolbar>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={8}>
            {getPostVersion.data && getEnvironment.data && (
              <Paper sx={{ p: 2 }}>
                <EditPostVersionForm
                  postVersion={getPostVersion.data}
                  environment={getEnvironment.data}
                  configuration={getPost.data?.type?.configuration}
                  onSuccess={() => getPostVersion.refetch()}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {getPost.data && (
              <>
                <Paper sx={{ mb: 2, p: 2 }}>
                  <EditPostStatusForm
                    post={getPost.data}
                    onSuccess={() => getPost.refetch()}
                  />
                </Paper>
                {organization !== '2-future-news' && (
                  <>
                    <Paper sx={{ mb: 2, p: 2 }}>
                      <EditPostIsSponsoredForm
                        post={getPost.data}
                      />
                    </Paper>
                    <Paper sx={{ mb: 2, p: 2 }}>
                      <EditPostOrderForm
                        post={getPost.data}
                      />
                    </Paper>
                  </>
                )}
                <Paper sx={{ mb: 2, p: 2 }}>
                  <Typography>Relevância: {getPost.data.relevance}</Typography>
                </Paper>
                <Paper sx={{ mb: 2, p: 2 }}>
                  <EditPostScoreForm
                    post={getPost.data}
                  />
                </Paper>
              </>
            )}
            {getPost.data && getEnvironment.data && (
              <Paper sx={{ mb: 2, p: 2 }}>
                <EditPostTypeForm
                  post={getPost.data}
                  spaceId={getEnvironment.data.space.id}
                />
              </Paper>
            )}
            {getPostVersion.data && (
              <>
                {showField('thumbnail', getPost.data?.type?.configuration) && (
                  <Paper sx={{ mb: 2, p: 2 }}>
                    <EditPostVersionThumbnail
                      postVersion={getPostVersion.data}
                      onSuccess={() => getPostVersion.refetch()}
                    />
                  </Paper>
                )}
                {showField('cover', getPost.data?.type?.configuration) && (
                  <Paper sx={{ mb: 2, p: 2 }}>
                    <EditPostVersionCover
                      postVersion={getPostVersion.data}
                      onSuccess={() => getPostVersion.refetch()}
                    />
                  </Paper>
                )}
                {showField('media', getPost.data?.type?.configuration) && (
                  <Paper sx={{ mb: 2, p: 2 }}>
                    <EditPostVersionMedia
                      postVersion={getPostVersion.data}
                      onSuccess={() => getPostVersion.refetch()}
                    />
                  </Paper>
                )}
              </>
            )}
            {getVersionsPage.data && showField('version', getPost.data?.type?.configuration) && (
              <Paper>
                <PostVersionsList postVersions={getVersionsPage.data.data} />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const showField = (field: string, configuration?: PostTypeConfiguration | null) => {
  return !(configuration?.fields[field] === false)
}
