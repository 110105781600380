import { Container, Toolbar, Typography } from '@mui/material'
import { useAuthentication } from '../../../hooks'
import { useTranslation } from 'react-i18next'

export function ProfilePage () {
  const { t } = useTranslation()
  const authentication = useAuthentication()

  return (
    <Container>
      <Toolbar disableGutters>
        <Typography variant="h1">
          {t('Profile')}
        </Typography>
      </Toolbar>
      {authentication.authenticationData?.name}
    </Container>
  )
}
