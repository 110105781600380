import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { GetPostResult } from '@thehive/cms-management-api'
import { useClient } from '../../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

export type EditPostOrderFormProps = {
  post: GetPostResult
}

export function EditPostOrderForm (props: EditPostOrderFormProps) {
  const { post } = props
  const { t } = useTranslation()
  const client = useClient()
  const [order, setOrder] = useState<string | null>(post.order ? post.order.toString().replace('.', '') : null)

  useEffect(() => {
    setOrder(post.order ? post.order.toString() : null)
  }, [post.order])

  const updateScore = useMutation({
    mutationKey: ['updatePostScore', post],
    mutationFn: async () => {
      try {
        const orderValue = order ? parseInt(order) : null
        await client.posts.updateOrder(post.id, { order: orderValue })
        enqueueSnackbar(t('EditPostPage:Post order updated'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(t('EditPostPage:Error updating post order'), { variant: 'error' })
      }
    }
  })

  return (
    <form>
      <Box display="flex">
        <Typography gutterBottom sx={{ flexGrow: 1 }}>
          {t('EditPostPage:Order')}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          disabled={updateScore.isPending}
          onClick={() => updateScore.mutate()}
        >
          {t('Save')}
        </Button>
      </Box>
      <TextField
        fullWidth
        type='number'
        margin="dense"
        label={'Valor'}
        value={order || ''}
        onChange={(event) => setOrder(event.target.value)}
      />
    </form>
  )
}
