import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useEntryVersion (
  entryVersionId?: string
) {
  const client = useClient()

  const getEntryType = useQuery({
    queryKey: ['getEntryVersionById', entryVersionId],
    queryFn: ({ signal }) =>
      client.entryVersions.getById(
        entryVersionId!,
        signal
      ),
    enabled: Boolean(entryVersionId)
  })

  return getEntryType
}
