import { useQuery } from '@tanstack/react-query'
import { useClient } from '.'

export function usePost (postId: string) {
  const client = useClient()

  const getPost = useQuery({
    queryKey: ['post', postId],
    queryFn: ({ signal }) => client.posts.getById(postId, signal)
  })

  return getPost
}
