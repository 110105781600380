import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import { useClient } from '../../../../hooks'
import { GetTagLocaleResult } from '@thehive/cms-management-api'

export type DeleteTagLocalesProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  tagLocales: GetTagLocaleResult[]
  tagId: string
}

export function DeleteTagLocalesDialog (props: DeleteTagLocalesProps) {
  const { open, onClose, onSuccess, tagLocales, tagId } = props
  const { t } = useTranslation()
  const client = useClient()
  const deleteTagLocales = useMutation({
    mutationKey: ['deleteTagLocales'],
    mutationFn: async () => {
      try {
        await client.tags.deleteTagLocales(tagId, {
          locales: tagLocales.map(({ locale }) => locale)
        })
        onClose()
        onSuccess()
        enqueueSnackbar(
          t('TagPage:Locales deleted', { count: tagLocales.length }),
          { variant: 'success' }
        )
      } catch {
        t('EnvironmentTagsPage:Error deleting locales')
      }
    }
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('TagPage:Delete locale', { count: tagLocales.length })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('This action is irreversible')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={deleteTagLocales.isPending} onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <Button
          disabled={deleteTagLocales.isPending}
          onClick={() => deleteTagLocales.mutate()}
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
