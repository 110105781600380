import AudioFileIcon from '@mui/icons-material/AudioFile'
import FileIcon from '@mui/icons-material/InsertDriveFile'
import ImageIcon from '@mui/icons-material/Image'
import React from 'react'

export type FileTypeIconProps = {
  type?: string | null
}

const icons = [
  ['image', ImageIcon],
  ['audio', AudioFileIcon],
  ['', FileIcon]
] as const

const defaultIcon = FileIcon

export function FileTypeIcon (props: FileTypeIconProps) {
  const { type } = props

  const match = icons.find(([prefix]) => type?.startsWith(prefix))

  return React.createElement(
    match?.[1] ?? defaultIcon,
    {
      color: 'action'
    }
  )
}
