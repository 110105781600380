import { Container, Paper, Toolbar, Typography } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useUsersPage } from '../../../hooks'
import { DataTable, RouterButton } from '../../../components'
import { useTranslation } from 'react-i18next'

export function UsersIndexPage () {
  const { t } = useTranslation()
  const getUsersPage = useUsersPage({
    pageSize: 1_000
  })

  return (
    <Container maxWidth="md">
      <Toolbar disableGutters>
        <Typography variant="h1" sx={{ flexGrow: 1 }}>{t('Users')}</Typography>
        <RouterButton
          to="/create-user"
          variant="contained"
          startIcon={<PersonAddIcon />}
        >
          {t('UsersIndexPage:New user')}
        </RouterButton>
      </Toolbar>

      <Paper>
        <DataTable
          getRowKey={row => row.id}
          loading={getUsersPage.isLoading}
          rows={getUsersPage.data?.data}
          cols={[
            {
              header: t('Name'),
              render: row => row.name
            }
          ]}
        />
      </Paper>
    </Container>
  )
}
