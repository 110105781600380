import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import * as yup from 'yup'
import { useClient } from '../../../../hooks/index.js'
import { useTranslation } from 'react-i18next'

export type ForgotPasswordFormProps = {
  onSuccess?: () => void
}

export function ForgotPasswordForm (props: ForgotPasswordFormProps) {
  const { t } = useTranslation()
  const client = useClient()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().email('E-mail inválido').required('Campo obrigatório')
    }),
    onSubmit: async values => {
      try {
        await client.authentication.forgotPassword(values)
        enqueueSnackbar(t('ForgotPasswordPage:Instructions sent'), { variant: 'success' })
        props.onSuccess?.()
      } catch (error) {
        if (client.isNotFound(error)) {
          enqueueSnackbar(t('ForgotPasswordPage:User not found'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('Unexpected error'), { variant: 'error' })
        }
      }
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled={formik.isSubmitting}
        fullWidth
        margin="dense"
        value={formik.values.email}
        name="email"
        label={t('Email')}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.email && formik.errors.email)}
        helperText={(formik.touched.email && formik.errors.email) || ' '}
      />
      <Button
        disabled={formik.isSubmitting}
        size="large"
        fullWidth
        type="submit"
        variant="contained"
      >
        {t('ForgotPasswordPage:Submit')}
      </Button>
    </form>
  )
}
