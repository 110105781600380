import { useNavigate, useParams } from 'react-router-dom'
import { useAuthentication, useClient, useEntryTypesPage, useEnvironment } from '../../../hooks'
import Container from '@mui/material/Container'
import { CardContent, Divider, Grid, Link, Paper, Skeleton, Toolbar, Typography } from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { t } from 'i18next'
import { Breadcrumb, DataTable, RouterButton } from '../../../components'
import { GetEntryTypesPageResult } from '@thehive/cms-management-api'
import { useCallback, useEffect, useState } from 'react'
import { RouterCard } from '../../../components/RouterCard'
import { PostsCountByPostTypeWidget } from './components/PostsCountByPostTypeWidget'
import { MostViewedPostsWidget } from './components/MostViewedPostsWidget'
import { ErrorAlert } from '../../../components/ErrorAlert/ErrorAlert'
import { useEntryTypeFoldersPage } from '../../../hooks/use-entry-type-folders-page'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
}

const HoverableFolderIcon = () => {
  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  return (
    <FolderIcon
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        fontSize: '128px',
        color: hover ? '#f5f5f5' : '#fff',
        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.2))',
        cursor: 'pointer',
        transition: 'color 0.5s ease, transform 0.5s ease',
        transform: hover ? 'scale(1.15)' : 'scale(1)'
      }}
    />
  )
}

export function EnvironmentPage () {
  const authentication = useAuthentication()
  const { organizationSlug, spaceSlug, environmentSlug } = useParams<ParamsType>()
  const [entryTypesOnFolder, setEntryTypesOnFolder] = useState<GetEntryTypesPageResult[]>()
  const [filteredEntryTypes, setFilteredEntryTypes] = useState<GetEntryTypesPageResult[]>([])
  const client = useClient()
  const navigate = useNavigate()
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const getEntryTypesPage = useEntryTypesPage({
    organization: organizationSlug,
    space: spaceSlug,
    pageSize: 1000
  })

  const getEntryTypeFoldersPage = useEntryTypeFoldersPage({
    environmentId: getEnvironment.data?.id || '',
    spaceId: getEnvironment.data?.space.id || '',
    pageSize: 1000
  })

  const buildEntryTypeHref = useCallback(
    (row: GetEntryTypesPageResult) => {
      const environment = getEnvironment.data
      return `/${environment?.space.organization.slug}/${environment?.space.slug}/${environment?.slug}/${row.slug}`
    },
    [getEnvironment.data]
  )

  const isNotFound = client.isNotFound(getEnvironment.error)

  const buildEntryTypeFolderHref = useCallback(
    (row: GetEntryTypesPageResult) => {
      const environment = getEnvironment.data
      return `/${environment?.space.organization.slug}/${environment?.space.slug}/${environment?.slug}/folders/${row.slug}/edit`
    },
    [getEnvironment.data?.space]
  )

  const handleEntryTypeFolderRowClick = useCallback(
    (row: GetEntryTypesPageResult) => {
      navigate(buildEntryTypeFolderHref(row))
    },
    [buildEntryTypeFolderHref]
  )

  useEffect(() => {
    if (getEntryTypeFoldersPage.data && getEntryTypesPage.data) {
      const entryTypeIdsInFolders = new Set<string>(
        getEntryTypeFoldersPage.data.data.flatMap(folder =>
          folder.entryTypeIds.map(entryType => entryType.id)
        )
      )

      const filteredEntryTypes = getEntryTypesPage.data.data.filter(
        entryType => !entryTypeIdsInFolders.has(entryType.id)
      )

      setFilteredEntryTypes(filteredEntryTypes)
    }
  }, [getEntryTypeFoldersPage.data, getEntryTypesPage.data])

  return (
    <Container maxWidth="md">
      {getEnvironment.isError && !isNotFound && (
        <ErrorAlert
          onClick={() => getEnvironment.refetch()}
          errorMessage={t('EnvironmentPage:Error on searching environment')}
          actionMessage={t('Try again')}
        />
      )}

      {isNotFound && (
        <ErrorAlert
          onClick={() => navigate(-1)}
          errorMessage={t('EnvironmentPage:Environment not found')}
          actionMessage={t('Return')}
        />
      )}

      {!getEnvironment.isError && (
      <>
        <Breadcrumb
          loading={getEnvironment.isLoading}
          data={[
            { label: t('Home'), to: '/' },
            { label: getEnvironment.data?.space.organization.name, to: `/${organizationSlug}` },
            { label: getEnvironment.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
            { label: getEnvironment.data?.name }
          ]}
        />

          {getEntryTypeFoldersPage.data?.data && getEntryTypeFoldersPage.data?.data.length > 0 &&
          <Toolbar disableGutters>
            <Typography variant="h1">
              {getEnvironment.isLoading && <Skeleton width={120} />}
              {t('Folders')} {getEnvironment.data?.name}
            </Typography>
          </Toolbar>
        }
        {entryTypesOnFolder &&
          <div style={{ display: 'flex', marginBottom: 15, alignItems: 'center', cursor: 'pointer' }} onClick={() => setEntryTypesOnFolder(undefined)}>
              <ArrowBack
                style={{
                  cursor: 'pointer'
                }}
              />
              {t('View folders')}
          </div>
        }
        <Grid container spacing={2} marginBottom={5}>
          {getEntryTypesPage.isLoading && (
            [...new Array(12)].map((_, index) => (
              <Grid key={index} item xs={6} md={4} lg={3}>
                <Skeleton variant="rounded" width="100%" height={56} />
              </Grid>
            ))
          )}
          {!entryTypesOnFolder
            ? getEntryTypeFoldersPage.data?.data.map(entryFolder => (
              <Grid key={entryFolder.name} item xs={6} md={4} lg={2} onClick={() => {
                setEntryTypesOnFolder(entryFolder.entryTypeIds)
              }}>
                <HoverableFolderIcon/>
                <Typography variant="subtitle1" style={{ margin: 'auto', textAlign: 'center' }}>
                  {entryFolder.name}
                </Typography>
              </Grid>
            ))
            : entryTypesOnFolder.length > 0
              ? entryTypesOnFolder.map(entryType => (
                <Grid key={entryType.name} item xs={6} md={4} lg={3}>
                  <RouterCard to={buildEntryTypeHref(entryType)} sx={{ height: '100%' }}>
                    <CardContent>
                      {entryType.name}
                    </CardContent>
                  </RouterCard>
                </Grid>
              ))
              : <Typography variant="body2" fontStyle="italic" color="text.secondary" textAlign="center" sx={{ mt: 2, width: '100%' }}>
                  {t('No folder data')}
                </Typography>

          }
        </Grid>

        <Toolbar disableGutters>
          <Typography variant="h1">
            {getEnvironment.isLoading && <Skeleton width={120} />}
              {t('No folder')} {getEnvironment.data?.name}
          </Typography>
        </Toolbar>

        <Grid container spacing={2}>
          {getEntryTypesPage.isLoading && (
            [...new Array(12)].map((_, index) => (
              <Grid key={index} item xs={6} md={4} lg={3}>
                <Skeleton variant="rounded" width="100%" height={56} />
              </Grid>
            ))
          )}

          {getEntryTypesPage.data && (
            <>
              {filteredEntryTypes.map(entryType => (
                <Grid key={entryType.name} item xs={6} md={4} lg={3}>
                  <RouterCard to={buildEntryTypeHref(entryType)} sx={{ height: '100%' }}>
                    <CardContent>
                      {entryType.name}
                    </CardContent>
                  </RouterCard>
                </Grid>
              ))}

              <Grid item xs={6} md={4} lg={3}>
                <RouterCard to={`/${organizationSlug}/${spaceSlug}/${environmentSlug}/posts`}>
                  <CardContent>
                    {t('Posts')}
                  </CardContent>
                </RouterCard>
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <RouterCard to={`/${organizationSlug}/${spaceSlug}/${environmentSlug}/tags`}>
                  <CardContent>
                    {t('Tags')}
                  </CardContent>
                </RouterCard>
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
                <RouterCard to={`/${organizationSlug}/${spaceSlug}/${environmentSlug}/assets`}>
                  <CardContent>
                    {t('Assets')}
                  </CardContent>
                </RouterCard>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PostsCountByPostTypeWidget
              organizationSlug={organizationSlug!}
              spaceSlug={spaceSlug!}
              environmentSlug={environmentSlug!}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MostViewedPostsWidget
              organizationSlug={organizationSlug!}
              spaceSlug={spaceSlug!}
              environmentSlug={environmentSlug!}
            />
          </Grid>
        </Grid>
      </>
      )}

      {authentication.isAdmin &&
        <Paper sx={{ my: 4 }}>
          <Toolbar>
            <Typography variant="h2" sx={{ flexGrow: 1 }}>
              {t('Folders')}
            </Typography>
            {authentication.isAdmin && (
              <RouterButton
                size="small"
                variant="outlined"
                to={`/${organizationSlug}/${spaceSlug}/${environmentSlug}/create-folder`}
              >
                {t('SpacePage:New entry type folder')}
              </RouterButton>
            )}
          </Toolbar>
          <Divider/>
          <DataTable
            skeletonRows={2}
            loading={getEntryTypeFoldersPage.isLoading}
            getRowKey={(row) => row.id}
            onRowClick={(row) => {
              handleEntryTypeFolderRowClick(row)
            }}
            rows={getEntryTypeFoldersPage.data?.data || []}
            cols={[
              {
                header: t('Name'),
                render: (row) => (
                  <Link
                    href={buildEntryTypeFolderHref(row)}
                    onClick={(event) => event.preventDefault()}
                  >
                    {row.name}
                  </Link>
                )
              }
            ]}
          />
        </Paper>
      }
    </Container>
  )
}
