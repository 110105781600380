import { useQuery } from '@tanstack/react-query'
import { Pagination } from '@thehive/cms-management-api'
import { useClient } from '.'

export function useApiKeysPage (params: Pagination) {
  const client = useClient()

  const getApiKeysPage = useQuery({
    queryKey: ['getApiKeysPage', params],
    queryFn: ({ signal }) => client.apiKeys.getPage(params, signal)
  })

  return getApiKeysPage
}
