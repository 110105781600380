import { Container, Paper, Skeleton, Toolbar, Typography } from '@mui/material'
import { Breadcrumb } from '../../../components'
import { useEntryTypeFolder, useEnvironment } from '../../../hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EditEntryTypeFolderForm } from './components/EditEntryTypeFolderForm'

type ParamsType = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  entryTypeFolderSlug: string
}

export function EditEntryTypeFolderPage () {
  const { organizationSlug, spaceSlug, environmentSlug, entryTypeFolderSlug } = useParams<ParamsType>()
  const getEnvironment = useEnvironment(organizationSlug!, spaceSlug!, environmentSlug!)
  const getEntryTypeFoldersPage = useEntryTypeFolder(
    organizationSlug!,
    spaceSlug!,
    environmentSlug!,
    entryTypeFolderSlug!
  )
  const { t } = useTranslation()
  return (
    <Container maxWidth="sm">
      <Breadcrumb
        loading={getEntryTypeFoldersPage.isLoading}
        data={[
          { label: t('Home'), to: '/' },
          { label: getEnvironment.data?.space.organization.name, to: `/${organizationSlug}` },
          { label: getEnvironment.data?.space.name, to: `/${organizationSlug}/${spaceSlug}` },
          { label: getEnvironment.data?.name, to: `/${organizationSlug}/${spaceSlug}/${environmentSlug}` },
          { label: getEntryTypeFoldersPage.data?.name }
        ]}
      />

      <Toolbar disableGutters>
        <Typography variant="h1">
          {getEntryTypeFoldersPage.isLoading && <Skeleton width="280px" height={48} />}
          {getEntryTypeFoldersPage.data?.name}
        </Typography>
      </Toolbar>
      <Paper sx={{ p: 2 }}>
        {!getEntryTypeFoldersPage.isLoading && getEntryTypeFoldersPage.data
          ? <EditEntryTypeFolderForm
            spaceId={getEnvironment.data?.space.id || ''}
            environmentId={getEnvironment.data?.id || ''}
            environmentSlug={environmentSlug || ''}
            folderData={getEntryTypeFoldersPage.data}
            entryTypeFolderId={getEntryTypeFoldersPage.data?.id || ''} organizationSlug={organizationSlug || ''} spaceSlug={spaceSlug || ''} />

          : <Skeleton width="280px" height={48} />
        }
      </Paper>
    </Container>
  )
}
