import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { useQuery } from '@tanstack/react-query'
import { GetMostViewedPostsQuery } from '@thehive/cms-management-api'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { BarChart } from '@mui/x-charts'

export type MostViewedPostsWidgetProps = {
  organizationSlug: string
  spaceSlug: string
  environmentSlug: string
  size?: number
  query?: Omit<GetMostViewedPostsQuery, 'organization' | 'space' | 'environment'>
}

const cardHeaderHeight = 56
const defaultSize = 200

export function MostViewedPostsWidget (props: MostViewedPostsWidgetProps) {
  const { query } = props
  const { t } = useTranslation()
  const size = props.size || defaultSize
  const client = useClient()
  const getData = useQuery({
    queryKey: ['mostViewedPosts', props],
    queryFn: () => client.analytics.getMostViewedPosts({
      ...query,
      organization: props.organizationSlug,
      space: props.spaceSlug,
      environment: props.environmentSlug
    })
  })

  return (
    <Box sx={{ my: 2 }}>
      {getData.isLoading && <Skeleton variant="rounded" height={size + cardHeaderHeight} />}
      {getData.data && (
        <Card>
          <CardContent>
            <Typography variant="h3">
              {t('EnvironmentPage:Most viewed posts')}
            </Typography>
            {getData.data?.data.length === 0 && (
              <Typography variant="body2" fontStyle="italic" color="text.secondary" textAlign="center" sx={{ mt: 2 }}>
                {t('No data')}
              </Typography>
            )}
          </CardContent>
          {getData.data && getData.data.data.length > 0 && (
            <BarChart
              xAxis={[
                {
                  id: 'most-viewed-posts',
                  data: getData.data.data?.map(row => row.post.title),
                  scaleType: 'band'
                }
              ]}
              series={[
                { data: getData.data.data?.map(row => row.views), type: 'bar' }
              ]}
              height={size}
            />
          )}
        </Card>
      )}
    </Box>
  )
}
