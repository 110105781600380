import { useQuery } from '@tanstack/react-query'
import { useClient } from './use-client'

export function useWebsite (
  organizationSlug: string,
  websiteSlug: string
) {
  const client = useClient()

  const getWebsite = useQuery({
    queryKey: ['getWebsiteBySlug', organizationSlug, websiteSlug],
    queryFn: ({ signal }) => client.websites.getBySlug(organizationSlug, websiteSlug, signal)
  })

  return getWebsite
}
