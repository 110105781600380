import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import { GetEnvironmentResult, GetPostVersionResult, PostTypeConfiguration, UpdatePostVersionCommand } from '@thehive/cms-management-api'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../../hooks'
import { enqueueSnackbar } from 'notistack'
import { Editor } from '@tinymce/tinymce-react'
import { TagsAutocomplete } from './TagsAutocomplete'
import { useCallback, useState } from 'react'
import FormLabel from '@mui/material/FormLabel'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ClearIcon from '@mui/icons-material/Clear'

export type EditPostVersionFormProps = {
  postVersion: GetPostVersionResult
  environment: GetEnvironmentResult
  configuration?: PostTypeConfiguration | null
  onSuccess?: () => void
}

export function EditPostVersionForm (props: EditPostVersionFormProps) {
  const { postVersion, environment, configuration } = props
  const { t } = useTranslation()
  const client = useClient()
  const [externalUrls, setExternalUrls] = useState<string[]>(postVersion.externalUrls ?? [''])

  const formik = useFormik({
    initialValues: {
      title: postVersion.title || '',
      description: postVersion.description || '',
      tags: postVersion.tags,
      content: postVersion.content || ''
    },
    onSubmit: async ({ tags, ...values }) => {
      const urls = externalUrls.filter(Boolean)

      try {
        const command: UpdatePostVersionCommand = {
          ...values,
          tagIds: tags.map(tag => tag.id),
          externalUrls: !urls.length ? [''] : urls
        }
        await client.postVersions.update(postVersion.id, command)
        props.onSuccess?.()
        enqueueSnackbar(t('EditPostPage:Post updated'), { variant: 'success' })
      } catch {
        enqueueSnackbar(t('EditPostPage:Error updating post'), { variant: 'error' })
      }
    }
  })

  const handleAddUrl = useCallback(() => {
    setExternalUrls(prev => {
      return [...prev, '']
    })
  }, [])

  const handleRemoveUrl = useCallback((index: number) => {
    setExternalUrls(prev => {
      const newUrls = prev.filter((_, i) => i !== index)
      if (!newUrls.length) return ['']
      return newUrls
    })
  }, [])

  return (
    <form onSubmit={formik.handleSubmit}>
      {showField('title', configuration) && (
        <TextField
          fullWidth
          margin="normal"
          label={t('Title')}
          name="title"
          value={formik.values.title}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      )}
      {showField('description', configuration) && (
        <TextField
          fullWidth
          multiline
          rows={3}
          margin="normal"
          label={t('Description')}
          name="description"
          value={formik.values.description}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      )}

      <FormControl sx={{ rowGap: 1, marginTop: 2 }} fullWidth>
        <FormLabel>{t('External URLs')}</FormLabel>
        {externalUrls?.map((externalUrl, index) =>
          <TextField
            fullWidth
            key={index}
            aria-describedby="external-url-input"
            value={externalUrl}
            onChange={e => {
              setExternalUrls(prev => {
                const newExternalUrls = [...prev]
                newExternalUrls[index] = e.target.value
                return newExternalUrls
              })
            }}
            InputProps={{
              endAdornment: removeUrlButton(index, handleRemoveUrl)
            }}
          />
        )}

        <Button
          sx={{ width: 'fit-content' }}
          variant="outlined"
          endIcon={<AddCircleIcon />}
          onClick={handleAddUrl}
        >
          <Typography fontSize={12}>Adicionar URL</Typography>
        </Button>
      </FormControl>
      {showField('tags', configuration) && (
        <TagsAutocomplete
          organizationSlug={environment.space.organization.slug}
          spaceSlug={environment.space.slug}
          environmentSlug={environment.slug}
          value={formik.values.tags}
          onChange={value => formik.setFieldValue('tags', value)}
        />
      )}
      {showField('content', configuration) && (
        <Editor
          tinymceScriptSrc={
            'https://cdn.jsdelivr.net/npm/tinymce@5.9.2/tinymce.min.js'
          }
          value={formik.values.content}
          onEditorChange={(content) =>
            formik.setFieldValue('content', content)}
          init={{
            height: 400,
            menubar: true,
            file_picker_types: 'image',
            automatic_uploads: true,
            image_title: true,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'preview',
              'help',
              'wordcount'
            ],
            file_picker_callback: async function (cb) {
              const input = document.createElement('input')
              input.setAttribute('type', 'file')
              input.setAttribute('accept', 'image/*')

              input.onchange = async function () {
                let file: any

                if (input.files) {
                  file = input.files[0]
                }

                const uploaded = await client.assets.createAsset({
                  environmentId: environment.id,
                  file,
                  isPublic: true
                })

                cb(uploaded.url || '', { title: uploaded.fileName })
              }

              input.click()
            },
            toolbar:
              'image | undo redo | blocks' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            font_formats:
              "DM Sans='DM Sans';Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino;Times New Roman=times new roman,times",
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');" +
              'body { font-family:DM Sans, Helvetica, Arial, sans-serif; font-size:14px }'
          }}
        />
      )}
      <Button type="submit" sx={{ mt: 1 }} variant="contained">
        {t('Save')}
      </Button>
    </form>
  )
}

const showField = (field: string, configuration?: PostTypeConfiguration | null) => {
  return !(configuration?.fields[field] === false)
}

function removeUrlButton (
  index: number,
  removeUrlCallback: (index: number) => void
) {
  return (
    <Tooltip title='Excluir URL' arrow placement='top'>
      <IconButton
        onClick={() => removeUrlCallback(index)}
        size='large'
      >
        <ClearIcon />
      </IconButton>
    </Tooltip>
  )
}
