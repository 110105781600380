import { GetEntriesPageQuery } from '@thehive/cms-management-api'
import { useClient } from './use-client'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export function useEntriesPage (params: GetEntriesPageQuery = {}) {
  const client = useClient()

  const getEntriesPage = useQuery({
    queryKey: ['getEntriesPage', params],
    queryFn: ({ signal }) => client.entries.getPage(params, signal),
    placeholderData: keepPreviousData
  })

  return getEntriesPage
}
